<template>
  <div>donate</div>
</template>

<script>
export default {
  name: "Donate",
  title: "Donate",
};
</script>

<style scoped>
</style>