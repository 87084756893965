<template>
  <div>
    <v-card color="accentSecondary" class="white--text">
      <v-row class="pt-3">
        <v-col align="center">
          <span class="headline text-md-h5 text-h6">{{ headline }}</span>
        </v-col>
      </v-row>
      <v-row class="pa-4">
        <v-col align="center">
          <span class="font-weight-bold text-lg-h3 text-sm-h4 text-h5">
            {{ question.question }}</span
          >
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col class="text-md-subtitle-1 text-subtitle-2" align="center">
          <div class="hidden-sm-and-down">
            <v-icon color="primary">mdi-atom</v-icon>
            {{ question.genre }}
          </div>
          <div class="hidden-md-and-up">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" align="center">
                <v-icon color="primary">mdi-atom</v-icon>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" align="center">
                {{ question.genre }}
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          class="text-md-subtitle-1 text-subtitle-2"
          align="center"
          v-if="isHost || gameState === 3"
        >
          <div class="hidden-sm-and-down">
            <v-icon color="primary">mdi-comment-check</v-icon>
            {{
              new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                question.solution
              )
            }}
            {{ displayUnit(question) }}
          </div>
          <div class="hidden-md-and-up">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" align="center">
                <v-icon color="primary">mdi-comment-check</v-icon>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" align="center">
                {{
                  new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                    question.solution
                  )
                }}
                {{ displayUnit(question) }}
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col class="text-md-subtitle-1 text-subtitle-2" align="center">
          <div class="hidden-sm-and-down">
            <v-icon color="primary">mdi-call-merge</v-icon>
            {{ question.type }}
          </div>
          <div class="hidden-md-and-up">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" align="center">
                <v-icon color="primary">mdi-call-merge</v-icon>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" align="center">
                {{ question.type }}
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isHost">
        <v-col align="center">
          <NextGameStateBtn :gameState="gameState" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import NextGameStateBtn from "../general/NextGameStateBtn";

export default {
  name: "Question",
  props: {
    question: Object,
    isHost: Boolean,
    headline: String,
    gameState: Number,
  },
  data() {
    return {
      calcToDisplay: {
        mm2: "mm²",
        cm2: "cm²",
        m2: "m²",
        km2: "km²",
        in2: "in²",
        ft2: "ft²",
        mi2: "mi²",
        mm3: "mm³",
        cm3: "cm³",
        m3: "m³",
        km3: "km³",
        in3: "in³",
        ft3: "ft³",
        C: "°C",
        F: "°F",
        "m/h": "mph",
      },
    };
  },
  methods: {
    displayUnit: function (question) {
      let unit = question.unit;
      if (this.calcToDisplay[unit]) {
        return this.calcToDisplay[unit];
      }
      return unit;
    },
  },
  components: {
    NextGameStateBtn,
  },
};
</script>

<style scoped>
</style>