<template>
  <div>
    <JoinViaInvite
      v-if="this.$route.params.roomName"
      :roomName="this.$route.params.roomName"
    />
    <Home v-else-if="activeComponent === -1" />
    <Host v-if="activeComponent === 10" :questions="questions" />
    <Lobby
      v-if="activeComponent === 0 || activeComponent === 1"
      :players="players"
      :pointsPerRound="pointsPerRound"
      :clientId="clientId"
      :gameState="activeComponent"
      :roomName="roomName"
      :isHost="isHost"
      :upcomingQuestion="currentQuestion"
      :currentRound="currentRound"
      :questionCount="questionCount"
    />
    <Question
      v-if="activeComponent === 2"
      :currentQuestion="currentQuestion"
      :players="players"
      :isHost="isHost"
      :answers="answers"
      :gameState="activeComponent"
      :countDown="countDown"
      :currentRound="currentRound"
      :questionCount="questionCount"
    />
    <Answers
      v-if="activeComponent === 3"
      :answers="answers"
      :players="players"
      :lastQuestion="currentQuestion"
      :isHost="isHost"
      :gameState="activeComponent"
      :countDown="countDown"
      :currentRound="currentRound"
      :questionCount="questionCount"
    />
    <Result
      v-if="activeComponent === 4"
      :pointsPerRound="pointsPerRound"
      :gameState="activeComponent"
      :clientId="clientId"
    />
  </div>
</template>

<script>
//MainViews
import Home from "@/views/gameViews/Home";
import Host from "@/views/gameViews/Host";
import Lobby from "@/views/gameViews/Lobby";
import Answers from "@/views/gameViews/Answers";
import Question from "@/views/gameViews/Question";
import Result from "@/views/gameViews/Result";
import JoinViaInvite from "@/views/gameViews/JoinViaInvite";

export default {
  name: "App",
  components: {
    //MainViews
    Home,
    Host,
    Lobby,
    Answers,
    Question,
    Result,
    JoinViaInvite,
  },
  data() {
    return {
      activeComponent: -1,
      clientId: "",
      isHost: false,
      questions: [],
      players: [],
      pointsPerRound: [],
      currentQuestion: {},
      answers: [],
      roomName: "",
      currentRound: 0,
      countDown: 0,
      questionCount: 0,
    };
  },
  mounted() {
    this.$socket.on("update", this.handleUpdate);
  },
  methods: {
    handleUpdate: function (data) {
      if (Object.prototype.hasOwnProperty.call(data, "state")) {
        this.activeComponent = data.state;
        // console.log(this.activeComponent);
      }
      if (Object.prototype.hasOwnProperty.call(data, "isHost")) {
        this.isHost = data.isHost;
      }
      if (Object.prototype.hasOwnProperty.call(data, "questions")) {
        this.questions = data.questions;
      }
      if (Object.prototype.hasOwnProperty.call(data, "players")) {
        this.players = data.players;
      }
      if (Object.prototype.hasOwnProperty.call(data, "pointsPerRound")) {
        this.pointsPerRound = data.pointsPerRound;
      }
      if (Object.prototype.hasOwnProperty.call(data, "currentQuestion")) {
        this.currentQuestion = data.currentQuestion;
      }
      if (Object.prototype.hasOwnProperty.call(data, "genre")) {
        this.genre = data.genre;
      }
      if (Object.prototype.hasOwnProperty.call(data, "type")) {
        this.type = data.type;
      }
      if (Object.prototype.hasOwnProperty.call(data, "answers")) {
        this.answers = data.answers;
      }
      if (Object.prototype.hasOwnProperty.call(data, "roomName")) {
        this.roomName = data.roomName;
      }
      if (Object.prototype.hasOwnProperty.call(data, "currentRound")) {
        this.currentRound = data.currentRound;
      }
      if (Object.prototype.hasOwnProperty.call(data, "clientId")) {
        this.clientId = data.clientId;
      }
      if (Object.prototype.hasOwnProperty.call(data, "countdown")) {
        this.countDown = data.countdown;
      }
      if (Object.prototype.hasOwnProperty.call(data, "questionCount")) {
        this.questionCount = data.questionCount;
      }
    },
  },
};
</script>

<style scoped>
</style>
