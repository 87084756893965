var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"card"}}):_vm._e(),(_vm.player && !_vm.loading)?_c('v-card',{attrs:{"color":"accentSecondary","elevation":"10"}},[_c('v-row',[_c('v-col',[_c('v-badge',{attrs:{"overlap":"","content":_vm.place,"bottom":"","offset-x":"45","color":_vm.badgeColor}},[_c('v-avatar',{staticStyle:{"border":"2px solid #41b883"},attrs:{"size":"70"}},[(_vm.player.avatar)?_c('v-img',{attrs:{"src":_vm.player.avatarUrl}}):_c('v-img',{attrs:{"src":"https://i.imgur.com/VVrWsei.png"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"primary--text pb-0 text-truncate"},[_vm._v(" "+_vm._s(_vm.player.name)+" ")])],1),_c('v-row',[_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"accentPrimary--text pt-0"},'v-col',attrs,false),on),[_vm._v(" "+_vm._s(_vm.player.elo)+" ")])]}}],null,false,3169183027)},[_c('span',[_vm._v("Elo")])])],1),_c('v-divider'),_c('v-row',{staticClass:"d-flex justify-start align-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"pr-0"},'v-col',attrs,false),on),[_vm._v(" "+_vm._s(_vm.player.gamesPlayed)+" ")])]}}],null,false,1932130372)},[_c('span',[_vm._v("Played-Games")])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({},'v-col',attrs,false),on),[_vm._v(" "+_vm._s(_vm.player.totalPoints)+" ")])]}}],null,false,2030730642)},[_c('span',[_vm._v("Points")])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"pl-0"},'v-col',attrs,false),on),[_vm._v(_vm._s(Math.round((_vm.player.winPercentage + Number.EPSILON) * 100))+"%")])]}}],null,false,1435386727)},[_c('span',[_vm._v("Win-Ratio")])])],1)],1):_vm._e(),(!_vm.player && !_vm.loading)?_c('v-card',{staticClass:"d-flex justify-start align-center",attrs:{"color":"accentSecondary","elevation":"10","height":"220"}},[_c('v-row',{staticClass:"px-4 primary--text"},[_c('v-col',[_c('span',[_vm._v("#"+_vm._s(_vm.place)+" is still available! Go get it!")])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }