<template>
  <div>
    <v-img :src="this.$globalState.authedUser.profileImg"></v-img>
  </div>
</template>

<script>
export default {
  name: "redirect",
  data() {
    return {
      authCode: "-1",
      accessToken: "-1",
      tokenType: "",
    };
  },
  mounted() {
    this.authCode = this.$route.query.code;
    this.handleAuth();
  },
  methods: {
    handleAuth: function () {
      let payload = {
        authCode: this.authCode,
      };
      this.$socket.emit("auth", payload);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
</style>