<template>
  <div>
    <v-card :color="colorCard" width="200">
      <v-row>
        <v-col>
          <span v-if="countDown === 0" class="font-weight-bold white--text">
            Time's up!
          </span>
          <span v-else class="font-weight-bold white--text"
            >{{ countDown }}s left</span
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Timer",
  props: {
    countDown: Number,
  },
  computed: {
    colorCard: function () {
      if (this.countDown === 0) {
        return "error";
      }
      return "secondary";
    },
  },
};
</script>