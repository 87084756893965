var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-overlay',{attrs:{"value":!_vm.validBrowser}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"info"},[_c('v-row',{staticClass:"mb-n8"},[_c('v-col',{staticClass:"mt-4",attrs:{"align":"center"}},[_c('span',{staticClass:"headline white--text font-weight-bold text-md-h5 text-h6"},[_vm._v("You are using Firefox!")])])],1),_c('v-row',[_c('v-col',{staticClass:"mt-4"},[_c('p',{staticClass:"mt-5 px-10"},[_vm._v(" We currently only support the following Browsers completely: "),_c('ul',[_c('li',[_vm._v("Google Chrome")]),_c('li',[_vm._v("Apple Safari")]),_c('li',[_vm._v("Microsoft Edge")])]),_c('br'),_vm._v(" Please choose one of those Browsers for now to achieve the best gaming experience. "),_c('br'),_vm._v("We are working on that issue to support Mozilla Firefox some time soon! ")])])],1),_c('v-row',{staticClass:"pb-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pl-4",attrs:{"align":"center"}},[_c('v-btn',{staticClass:"secondary",on:{"click":function($event){_vm.validBrowser = true}}},[_vm._v("Got It!")])],1)],1)],1)],1)],1)],1),_c('div',[_c('div'),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('IconSvg',{staticClass:"mb-0 logo",staticStyle:{"max-width":"450px"}}),_c('NameSvg',{staticClass:"mb-0 logo",staticStyle:{"max-width":"450px"}})],1)],1),_c('div',{staticClass:"hidden-md-and-up"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-dialog',{attrs:{"width":_vm.gameInformationWidth},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary","elevation":"20","large":""}},'v-btn',attrs,false),on),[_vm._v(" Join Game ")])]}}])},[_c('GameInformation',{attrs:{"width":_vm.gameInformationWidth}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"secondary","elevation":"20","large":"","loading":_vm.loading,"disables":_vm.loading},on:{"click":function($event){_vm.loader = 'loading';
              _vm.handleHost();}}},[_vm._v(" Host Game ")])],1)],1)],1),_c('v-row',{staticClass:"hidden-sm-and-down",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2","align":"end"}},[_c('v-dialog',{attrs:{"width":_vm.gameInformationWidth},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary","elevation":"20","large":""}},'v-btn',attrs,false),on),[_vm._v(" Join Game ")])]}}])},[_c('GameInformation',{attrs:{"width":_vm.gameInformationWidth}})],1)],1),_c('v-col',{attrs:{"cols":"2","align":"start"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"secondary","elevation":"20","large":"","loading":_vm.loading,"disables":_vm.loading},on:{"click":function($event){_vm.loader = 'loading';
            _vm.handleHost();}}},[_vm._v(" Host Game ")])],1)],1),_c('v-row',{staticClass:"mt-16",attrs:{"align":"end"}},[_c('v-col',{attrs:{"align":"center"}},[_vm._v(" Created with "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleEasterEgg()}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-cards-heart")])],1),_vm._v(" in Scheinfeld ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }