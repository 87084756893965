<template>
  <div>
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3" align="center">
        <SubmittedPlayers :players="players" :answers="answers" />
      </v-col>
      <v-col cols="12" md="6" align="center">
        <ProgressBar
          :questionCount="questionCount"
          :currentQuestionNumber="currentRound"
        />
        <QuestionCard
          class="mb-5"
          :question="currentQuestion"
          :isHost="isHost"
          headline="Current Question"
          :gameState="gameState"
        />
        <v-card v-if="!isHost" color="accentSecondary" class="white--text my-5">
          <v-row>
            <v-col align="center">
              <span class="headline text-md-h5 text-h6">Your Guess</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <Timer :countDown="countDown" />
            </v-col>
          </v-row>
          <v-form v-model="validAnswer" v-on:submit.prevent>
            <v-row>
              <v-col align="center">
                <!-- Disabled until #45 is ready -->
                <!-- <vuetify-money
                  class="px-4"
                  style="width: 260px"
                  v-model="answerField"
                  v-bind:options="options"
                  background-color="secondaryAccent"
                  label="Answer"
                /> -->
                <v-text-field
                  class="px-4"
                  dark
                  style="width: 250px"
                  label="Answer"
                  v-model="answerField"
                  :rules="[isNotEmpty, containsComma]"
                  type="number"
                  @keyup.enter="handleSubmitAnswer()"
                ></v-text-field>
              </v-col>
              <v-col v-if="currentQuestion.type != 'default'" align="center">
                <v-select
                  dark
                  style="width: 150px"
                  label="Unit"
                  :items="dropDownItems[currentQuestion.type]"
                  v-model="unitSelect"
                  :rules="[isNotEmpty, containsComma]"
                  item-color
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col align="center">
              <v-btn
                color="primary"
                :disabled="!validAnswer || countDown === 0"
                @click="handleSubmitAnswer()"
                >Submit my Answer!</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <AnswersList
          v-if="isHost"
          :answers="answers"
          :countDown="countDown"
          :isHost="isHost"
          :gameState="gameState"
        />
        <SubmittedPlayers
          class="my-5 hidden-md-and-up"
          :players="players"
          :answers="answers"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubmittedPlayers from "@/components/Questions/SubmittedPlayers";
import QuestionCard from "@/components/Questions/QuestionCard";
import AnswersList from "@/components/Answers/AnswersList";
import Timer from "@/components/Answers/Timer";
import ProgressBar from "@/components/general/ProgressBar";

export default {
  name: "Question",
  title: "Questionphase",
  props: {
    currentQuestion: Object,
    players: Array,
    isHost: Boolean,
    answers: Array,
    gameState: Number,
    countDown: Number,
    currentRound: Number,
    questionCount: Number,
  },
  components: {
    SubmittedPlayers,
    QuestionCard,
    AnswersList,
    Timer,
    ProgressBar,
  },
  mounted() {
    if (this.currentQuestion.type != "default") {
      this.unitSelect = this.dropDownItems[this.currentQuestion.type][0];
    }
  },
  data() {
    return {
      options: {
        locale: "pt-BR",
        length: 30,
        precision: 0,
      },

      answerField: "",
      unitSelect: {},
      validAnswer: false,
      dropDownItems: {
        length: ["mm", "cm", "m", "km", "in", "ft-us", "ft", "mi"],
        mass: ["mcg", "mg", "g", "kg", "oz", "lb", "mt", "t"],
        area: ["mm²", "cm²", "m²", "ha", "km²", "in²", "ft²", "ac", "mi²"],
        volume: [
          "mm³",
          "cm³",
          "ml",
          "l",
          "kl",
          "m³",
          "km³",
          "tsp",
          "Tbs",
          "in³",
          "fl-oz",
          "cup",
          "pnt",
          "qt",
          "gal",
          "ft³",
          "yd3",
        ],
        temperature: ["°C", "°F", "K", "R"],
        time: ["ns", "mu", "ms", "s", "min", "h", "d", "week", "month", "year"],
        speed: ["m/s", "km/h", "mph", "knot", "ft/s"],
        digital: ["b", "Kb", "Mb", "Gb", "Tb", "B", "KB", "MB", "GB", "TB"],
      },
      displayToCalcMap: {
        "mm²": "mm2",
        "cm²": "cm2",
        "m²": "m2",
        "km²": "km2",
        "in²": "in2",
        "ft²": "ft2",
        "mi²": "mi2",
        "mm³": "mm3",
        "cm³": "cm3",
        "m³": "m3",
        "km³": "km3",
        "in³": "in3",
        "ft³": "ft3",
        "°C": "C",
        "°F": "F",
        mph: "m/h",
      },
    };
  },
  methods: {
    handleSubmitAnswer: function () {
      if (this.currentQuestion.type === "default") {
        this.unitSelect = "default";
      }
      let calcUnit = this.displayToCalcMap[this.unitSelect];
      let payloadUnit = this.unitSelect;
      if (calcUnit) {
        payloadUnit = calcUnit;
      }
      let payload = {
        answer: this.answerField,
        unit: payloadUnit,
      };
      this.$socket.emit("submitAnswer", payload);
    },
    //Text-Field rules
    isNotEmpty: function (input) {
      if (input == "") {
        return false;
      } else {
        return true;
      }
    },
    containsComma: function (input) {
      if (input.includes(",")) {
        return "Use point instead of comma";
      }
      return true;
    },
  },
};
</script>

<style scoped>
.theme--light.v-list {
  background: #485f78;
}
.v-list-item__content {
  color: white;
}
.theme--light.v-list-item:hover:before {
  opacity: 0.5;
}
</style>