<template>
  <div>
    <v-row>
      <v-col class="pa-0 py-2 pl-2" cols="3" align="center">
        <v-avatar style="border: 2px solid #41b883" size="30">
          <v-img :src="profile"></v-img>
        </v-avatar>
      </v-col>
      <v-col class="pa-0 py-2" cols="9" align="start">
        <v-card dark width="180" height="35" :color="cardColor">
          <div align="center">
            <span
              class="text-h6 font-weight-light d-inline-block text-truncate"
              style="max-width: 180px"
              >{{ Name }}</span
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PlayerCard",
  props: {
    Name: String,
    isSubmitted: Boolean,
    isPlayerList: Boolean,
    image: String,
  },
  computed: {
    cardColor: function () {
      if (this.isPlayerList) {
        return "primary";
      } else {
        if (this.isSubmitted) {
          return "primary";
        }
        return "pending";
      }
    },
    profile: function () {
      if (this.image.includes("/null")) {
        return "https://i.imgur.com/VVrWsei.png";
      }
      return this.image;
    },
  },
};
</script>

<style scoped>
</style>