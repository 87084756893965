<template>
  <div>
    <v-btn class="white--text" color="primary" @click="handleNextState()">
      {{ stateTextMap[this.gameState] }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "NextGameStateBtn",
  props: {
    gameState: Number,
  },
  data() {
    return {
      stateTextMap: {
        0: "Start Game",
        1: "Start Round",
        2: "End Guess Phase",
        3: "End Answer Overview",
        4: "Go To Start",
      },
    };
  },
  methods: {
    handleNextState: function () {
      this.$socket.emit("nextGameState");
    },
  },
};
</script>

<style scoped>
</style>