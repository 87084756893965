<template>
  <div>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <span class="primary--text text-md-h4 text-h5 font-weight-bold">
          Submit your Feedback
        </span>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <v-form ref="form" v-model="validFeedback">
          <v-row>
            <v-col>
              <v-text-field
                label="Title"
                v-model="title"
                placeholder="Enter your title here"
                hint="Choose something short but meaningful"
                :rules="[isNotEmpty, multipleWords]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                counter
                placeholder="Add your thoughts :)"
                label="Feedback"
                :rules="[isNotEmpty, multipleWords]"
                v-model="feedback"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <v-btn
          :disabled="!validFeedback"
          class="primary"
          @click.stop="handleSubmit()"
          >Send my Feedback</v-btn
        >
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span class="secondary--text text-md-h5 text-h6 font-weight-bold"
          >How it works...</span
        >
        <div class="mt-3">
          Enter your title and your feedback. After submitting your feedback our
          team will review and check it.
          <br />Thank you for your Feedback! :)
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "submitFeedback",
  title: "Feedback-Submission",
  data() {
    return {
      validFeedback: false,
      title: "",
      feedback: "",
    };
  },
  methods: {
    isNotEmpty: function (input) {
      if (input == "") {
        return "This field must be filled!";
      } else {
        return true;
      }
    },
    multipleWords: function (input) {
      let wordcount = input.split(" ").length;
      if (wordcount > 1) {
        return true;
      }
      return "Mulitple words required";
    },
    handleSubmit: function () {
      let submission = {
        title: this.title,
        feedback: this.feedback,
      };
      axios
        .post(this.$restApiUrl + "/submit/feedback", JSON.stringify(submission))
        .then((response) => {
          let Notify = {
            duration: 4000,
            type: null,
            headline: "Feedback submission",
            message: "",
          };
          if (response.data.state) {
            Notify.type = "success";
            Notify.message = "Feedback successfully submitted!";
            this.$refs.form.reset();
          } else {
            Notify.type = "error";
            Notify.message = response.data.msg;
          }
          this.$globalState.addNotification(Notify);
        });
    },
  },
};
</script>

<style scoped>
</style>