<template>
  <div class="lobby">
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3" align="center">
        <PlayerList :players="players" />
      </v-col>
      <v-col cols="12" md="6" align="center">
        <v-card
          v-if="gameState === 0 && !isHost"
          color="accentSecondary"
          class="mb-5 white--text"
          height="70"
        >
          <v-row>
            <v-col align="center">
              <span class="headline white--text"
                >The game is starting soon...</span
              >
            </v-col>
          </v-row>
        </v-card>
        <ProgressBar
          v-if="gameState === 1"
          :questionCount="questionCount"
          :currentQuestionNumber="currentRound"
        />
        <QuestionCard
          v-if="isHost"
          class="mb-5"
          :question="upcomingQuestion"
          :isHost="isHost"
          headline="Upcoming Question"
          :gameState="gameState"
        />
        <Leaderboard
          class="mb-5"
          v-if="gameState != 0 && currentRound != 0"
          :pointsPerRound="pointsPerRound"
          :clientId="clientId"
          :gameState="gameState"
        />
        <Invite
          class="mb-5 hidden-md-and-up"
          v-if="gameState === 0"
          :roomName="roomName"
        />
        <PlayerList class="mb-5 hidden-md-and-up" :players="players" />
      </v-col>
      <v-col class="hidden-sm-and-down" cols="3" align="center">
        <Invite v-if="gameState === 0" :roomName="roomName" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PlayerList from "@/components/Lobby/PlayerList";
import Invite from "@/components/Lobby/Invite";
import Leaderboard from "@/components/Lobby/Leaderboard";
import QuestionCard from "@/components/Questions/QuestionCard";
import ProgressBar from "@/components/general/ProgressBar";

export default {
  name: "Lobby",
  title: "Lobby",
  props: {
    players: Array,
    pointsPerRound: Array,
    clientId: String,
    gameState: Number,
    roomName: String,
    isHost: Boolean,
    upcomingQuestion: Object,
    currentRound: Number,
    questionCount: Number,
  },
  components: {
    PlayerList,
    Invite,
    Leaderboard,
    QuestionCard,
    ProgressBar,
  },
};
</script>

<style scoped>
</style>