<template>
  <div>
    <v-card width="250" color="accentSecondary" class="white--text">
      <v-row>
        <v-col align="center">
          <div align="center">
            <span class="headline">Lobby</span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="answeredPlayers.length > 0">
        <v-col class="mt-0 pt-0">
          <span class="subtitle-1">Players: {{ answeredPlayers.length }}</span>
        </v-col>
      </v-row>
      <div
        v-for="player in answeredPlayers"
        :key="player.playerId"
        align="center"
      >
        <PlayerCard
          class="mb-n2"
          :Name="player.playerName"
          :isSubmitted="player.isSubmitted"
          :isPlayerList="false"
          :image="player.image"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import PlayerCard from "../Lobby/PlayerList/PlayerCard";

export default {
  name: "SubmittedPlayers",
  components: {
    PlayerCard,
  },
  props: {
    players: Array,
    answers: Array,
  },
  computed: {
    answeredPlayers: function () {
      let mergeArray = [];

      for (let i = 0; i < this.players.length; i++) {
        let element = this.players[i];
        let isSubmitted = false;
        if (
          this.answers.findIndex(function (item) {
            return item.playerId === element.id;
          }) != -1
        ) {
          isSubmitted = true;
        }
        let profileImg = "https://i.imgur.com/VVrWsei.png";
        if (Object.prototype.hasOwnProperty.call(this.players[i], "authUser")) {
          if (this.players[i].authUser.avatar) {
            profileImg = this.players[i].authUser.image;
          }
        }
        mergeArray[i] = {
          playerId: this.players[i].id,
          playerName: this.players[i].name,
          isSubmitted: isSubmitted,
          image: profileImg,
        };
      }
      return mergeArray;
    },
  },
};
</script>

<style scoped>
</style>