<template>
  <div>
    <v-card
      class="pa-0 white--text"
      width="325"
      height="110"
      color="accentSecondary"
    >
      <v-container class="pa-2">
        <v-row>
          <v-col align="center" class="mt-n3 mb-n3">
            <span class="text-h6">Game Invite Link</span>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col cols="10">
            <v-text-field
              dense
              id="invite-link"
              background-color="primary"
              dark
              readonly
              outlined
              v-model="link"
              >test</v-text-field
            >
          </v-col>
          <v-col cols="2">
            <v-btn class="ml-n3" icon color="primary" v-clipboard:copy="link">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Invite",
  props: {
    roomName: String,
  },
  data() {
    return {
      link: this.buildInviteLink(),
    };
  },
  methods: {
    buildInviteLink: function () {
      let baseURL = this.$gameUrl + "/game/";
      let roomName = this.roomName;
      let invite = baseURL + roomName;
      return invite;
    },
  },
};
</script>

<style scoped>
</style>