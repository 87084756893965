import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import darkTheme from "./vuetify/darkTheme";
import lightTheme from "./vuetify/lightTheme";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: darkTheme,
            light: lightTheme,
        },
        options: { customProperties: true },
    }
});
