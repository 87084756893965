<template>
  <div>
    <v-overlay :value="overlayActive">
      <v-row justify="center">
        <v-col cols="12" md="6" align="center">
          <v-card class="accentPrimary">
            <v-row>
              <v-col class="mt-4">
                <span
                  class="headline white--text font-weight-bold text-md-h5 text-h6"
                  >Enjoyed GuessGame?</span
                >
                <p class="mt-5 px-10">
                  Please consider submitting Feedback. Even if you didn't enjoy
                  GuessGame. We're happy about every Feedback we get. Even
                  negative :D
                </p>
              </v-col>
            </v-row>
            <v-row class="pb-4" justify="center">
              <v-col class="pl-4" align="center">
                <v-btn class="secondary" @click="overlayActive = false"
                  >Maybe Later</v-btn
                >
              </v-col>
              <v-col class="pr-4" align="center">
                <v-btn class="primary" @click="$router.push('submit/Feedback')"
                  >Submit Feedback</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>
    <v-row justify="center">
      <v-col cols="12" md="6" align="center">
        <v-card color="accentSecondary" class="white--text">
          <v-row>
            <v-col align="center">
              <span
                class="headline white--text font-weight-bold text-md-h5 text-h6"
                >Thank you for playing GuessGame!</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" @click="refreshSite()"
                >Start New Game</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <span class="white--text">Here are your results:</span>
            </v-col>
          </v-row>
        </v-card>
        <Leaderboard
          class="mt-5"
          :pointsPerRound="pointsPerRound"
          :clientId="clientId"
          :gameState="gameState"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Leaderboard from "@/components/Lobby/Leaderboard";

export default {
  name: "Result",
  title: "Game finished",
  data() {
    return {
      overlayActive: true,
    };
  },
  props: {
    pointsPerRound: Array,
    gameState: Number,
    clientId: String,
  },
  components: {
    Leaderboard,
  },
  methods: {
    refreshSite: function () {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
</style>