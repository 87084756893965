<template>
  <div>
    <v-card color="accentSecondary" class="white--text">
      <v-row>
        <v-col align="center">
          <span class="headline font-weight-bold text-md-h5 text-h6"
            >Leaderboard</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card color="accentSecondary" class="white--text" elevation="0">
            <v-row>
              <v-col align="center" class="font-weight-bold"> Player </v-col>
              <v-col align="center" class="font-weight-bold"> Points </v-col>
              <v-col
                v-if="gameState !== 4"
                align="center"
                class="font-weight-bold"
              >
                Action
              </v-col>
            </v-row>
          </v-card>
          <LbCard
            class="px-4"
            v-for="player in pointsLastRound"
            :key="player.playerId"
            :player="player"
            :clientId="clientId"
            :gameState="gameState"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import LbCard from "./Leaderboard/LbCard";

export default {
  name: "Leaderboard",
  props: {
    pointsPerRound: Array,
    gameState: Number,
    clientId: String,
  },
  components: {
    LbCard,
  },
  computed: {
    pointsLastRound: function () {
      return this.addLeaderboardActions(this.pointsPerRound);
    },
  },
  methods: {
    addLeaderboardActions: function (array) {
      if (array.length > 1) {
        //-1 -> downgrade
        // 0 -> equal
        // 1 -> upgrade
        let currentRound = array[array.length - 1];
        let lastRound = array[array.length - 2];

        currentRound = currentRound.sort((a, b) => b.points - a.points);
        lastRound = lastRound.sort((a, b) => b.points - a.points);

        for (let i = 0; i < currentRound.length; i++) {
          let element = currentRound[i];
          let lastPosition = lastRound.findIndex(function (item) {
            return item.playerId === currentRound[i].playerId;
          });
          if (i > lastPosition) {
            element.lbAction = -1;
          } else if (i < lastPosition) {
            element.lbAction = 1;
          } else {
            element.lbAction = 0;
          }
        }

        return currentRound;
      } else {
        let currentRound = array[0].sort((a, b) => b.points - a.points);
        for (let player of currentRound) {
          player.lbAction = 0;
        }
        return currentRound;
      }
    },
  },
};
</script>

<style scoped>
</style>