import Vue from 'vue'
import VueRouter from 'vue-router'
import game from '../views/game.vue'
import donate from '../views/donate.vue'
import submitQuestion from '../views/submitQuestion.vue'
import submitBug from '../views/submitBug.vue'
import submitFeedback from '../views/submitFeedback.vue'
import documentation from '../views/documentation.vue'
import discord from '../views/discord.vue'
import redirect from '../views/redirect.vue'
import profile from '../views/profile.vue'
import alltimeLeaderboard from '../views/alltimeLeaderboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/game/:roomName',
    component: game,
  },
  {
    path: '/',
    component: game,
  },
  {
    path: '/donate',
    component: donate
  },
  {
    path: '/submit/Question',
    component: submitQuestion
  },
  {
    path: '/submit/Bug',
    component: submitBug
  },
  {
    path: '/submit/Feedback',
    component: submitFeedback
  },
  {
    path: '/documentation',
    component: documentation
  },
  {
    path: '/discord',
    component: discord
  },
  {
    path: '/auth/redirect',
    component: redirect
  },
  {
    path: '/profile',
    component: profile
  },
  {
    path: '/leaderboard',
    component: alltimeLeaderboard
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 60 }
      }
    }
  },
})

export default router
