export default {
    primary: {
        base: '#41B883'
    },
    secondary: {
        base: '#485f78',
    },
    accentPrimary: {
        base: '#2c805b',
    },
    accentSecondary: {
        base: '#35495E',
    },
    error: {
        base: '#B00020',
    },
    warning: {
        base: '#FFC400',
    },
    info: {
        base: '#03A9F4',
    },
    success: {
        base: '#009649',
    },
    pending: {
        base: '#929292'
    }
}