<template>
  <div>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <span id="WhatIsGuessGame" class="primary--text text-md-h4 text-h5 font-weight-bold">
          GuessGame - Documentation
        </span>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span class="secondary--text text-md-h5 text-h6 font-weight-bold"
          >What is GuessGame?</span
        >
        <div class="mt-3">
          <p>
          GuessGame is a free online multiplayer browser-game. The main aim of
          the game is to guess a specific value as close as possible to the
          solution and to be closer than your friends.
          </p>
          <p>
          The game is currently still in development. So not everything
          works perfect. But we are working on that ;) Make sure to
          <router-link to="/submit/feedback">submit your feedback</router-link>
          or <router-link to="/submit/bug"> report a bug </router-link> you
          found!
          </p>
          <p>The game currently only contains 122 questions of 16
          genres you and your friends can guess on. But you can help adding more
          questions to the game by
          <router-link to="/submit/question">
            submitting a question
          </router-link>
          you think is worth guessing.
          </p>
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span class="secondary--text text-md-h5 text-h6 font-weight-bold"
          >How to Play</span
        >
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span
        id="Rules"
          class="secondary--text text-md-h6 text-subtitle-1 font-weight-bold"
          >Rules</span
        >
        <div class="mt-3">
          <p>
          The rules are simple:
          </p>
          <ul>
            <li>
              One player hosts a Game, sets the time per question and selects
              the questions
            </li>
            <li>Players can join via the invite-link the host shares</li>
            <li>
              When all players are in, the host can close the lobby and start
              the game
            </li>
            <li>
              The host reads out the question to the players. Preferably on a
              Voice-Chat like our
              <router-link to="/discord"> Discord-Server </router-link>
            </li>
            <li>
              Now the players can submit their guesses until the time is over,
              which the host set
            </li>
            <li>
              After all players submitted the host can end the Guess-Phase and
              the players see their answers
            </li>
            <li>
              After you all laughed about your wild guesses the host can move on
              to the game lobby where the leaderboard is displayed
            </li>
            <li>The host can now start the next round of GuessGame</li>
          </ul>
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span id="HostGame"
          class="secondary--text text-md-h6 text-subtitle-1 font-weight-bold"
          >Host a Game</span
        >
        <div class="mt-3">
          <p>
          If you are the chosen one to moderate and host the game you simply
          click on “Host Game”. After that you have to set the following values:
          </p>
          <p>
          <ul>
            <li>
              <span class="primary--text">Time Per Question</span>: This sets
              the time every player has to take his guess on the current
              question. If it’s your first game you might choose a longer time
              per question to get used to the game. If you want to spice it up a
              little you can set the time per question on a low value to achieve
              some really weird guesses of your friends :P.
            </li>
            <li>
              <span class="primary--text">Questions</span>: Select the questions
              you want your friends to guess on. The questions are summarized by
              genre. You can order the questions inside of each genre by
              clicking on “Type” at the top of the questions-table. Available
              question-types are:
            </li>
            <ul>
              <li v-for="type in questionTypes" :key="type">
                {{ type }}
              </li>
            </ul>
          </ul>
          </p>
          <p>
            After setting those values you can host your lobby by clicking on
            “Finished! Host my Game!”. You’re ready to invite your friends to
            your game and start playing.
          </p>
          <p>
            <span class="font-weight-bold">Keep in mind</span>: You are
            the master of this game. You are in control of the Game-Phase. You
            can push the game to the next phase by the button depending on the
            current phase (“Start Game”, “Start Round”, “End Guess Phase”, “End
            Answer Overview”). BUT If you leave the game it ends for your mates
            too.
          </p>
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span
        id="JoinGame"
          class="secondary--text text-md-h6 text-subtitle-1 font-weight-bold"
          >Join a Game</span
        >
        <div class="mt-3">
          <p>There are two ways to enter a game of GuessGame:</p>
          <p>
            <ul>
              <li><span class="font-weight-bold">Join via Invite-Link</span>: If you received an Invite-Link from another player, simply enter that link into your browser, enter a name and click on “Join Game”.</li>
              <li><span class="font-weight-bold">Join via the Main-GuessGame-Page</span>: Click on “Join Game”, enter the 5-digit Room-Name and your Player-Name. If you’re done click on “Submit & Join”.</li>
            </ul>
          </p>
          <p>If you’re in an active lobby of GuessGame you just need to wait until the host starts the round.</p>
          <p>After the round starts, you’ll see a Question-Card containing the current question, genre and question-type. You’ll see a Guess-Card too. It contains a field to enter your answer and if the question-type is anything else than “default” you’ll have a dropdown-box to choose the unit for your question. The dropdown-box only contains valid units for the current question-type. You’ll also see a timer counting down. You can submit your guess until the timer is finished. So, keep that in mind.</p>
          <p>That is all you need to do. The host is controlling everything else.</p>
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span class="secondary--text text-md-h5 text-h6 font-weight-bold"
          >Login via Discord</span
        >
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span
        id="Advantages"
          class="secondary--text text-md-h6 text-subtitle-1 font-weight-bold"
          >Advantages</span
        >
        <div class="mt-3">
          <p>So, you may ask yourself “Why should I login via discord if I can play the game without being logged in?” – Our simple answer to this question is that being logged in via discord comes with some advantages for you.</p>
          <p>Advantages:
            <ul>
              <li>Tracking your stats</li>
              <ul>
                <li>Gained points</li>
                <li>Played games</li>
                <li>Won games</li>
                <li>Win-Ratio</li>
              </ul>
              <li>Tracking your game history</li>
              <ul>
                <li>Date of the game</li>
                <li>Duration of the game</li>
                <li>Your placement in the game</li>
                <li>Player count</li>
                <li>Question count</li>
              </ul>
              <li>A chance for a place in the <router-link to="/leaderboard"> GuessGame Alltime-Leaderboard </router-link></li>
            </ul>
          </p>
          <p>The list of advantages may increase in the future</p>
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span
        id="HowToLogin"
          class="secondary--text text-md-h6 text-subtitle-1 font-weight-bold"
          >How to Login
          </span>
          <p>Now that we got you thinking about why you should login via discord, you may want to know how you can do so. Since we are using an OAuth2-Interface provided by discord itself its really simple.</p>
          <p>If you are playing on a desktop device you need to click on the login-icon in the upper right corner and click on “Login via Discord”. For mobile-users open the drawer by tapping on the drawer-icon in the upper left corner. Then tap on “Login via Discord” in the bottom half of the expanded drawer.</p>
          <p>After that you will be redirected to discord. You need to login with your discord account and authorize GuessGame to use your Discord-ID which includes your Discord-Name, Discord-Number, and your Profile-Image. After the authorization you will be redirected to the GuessGame-Homepage. </p>
          <p>It’s as easy as that!</p>
          </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6"></v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6"></v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "documentation",
  title: "Documentation",
  data() {
    return {
      questionTypes: [
        "Length",
        "Mass",
        "Area",
        "Volume",
        "Temperature",
        "Time",
        "Speed",
        "Digital",
        "Default (No specific type)",
      ],
    };
  },
};
</script>

<style scoped>
</style>