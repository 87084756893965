<template>
  <div>
    <v-row>
      <v-col align="center">
        <div style="cursor: pointer">
          <IconSvg style="max-width: 450px" class="mb-0 logo" />
          <NameSvg style="max-width: 450px" class="mb-0 logo" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <span class="headline white--text font-weight-bold"
          >Enter your name to start</span
        >
        <v-form class="mt-10" v-model="lobbyJoin" v-on:submit.prevent>
          <v-text-field
            style="width: 150px"
            label="Player Name"
            counter
            v-model="playerNameField"
            :rules="[isNotEmpty, lengthGreater1, lengthSmaller21]"
            @keyup.enter="
              loader = 'loading';
              handleJoin();
            "
          >
          </v-text-field>
        </v-form>
        <v-btn
          class="white--text mt-6"
          color="primary"
          :disabled="!lobbyJoin"
          elevation="10"
          large
          :loading="loading"
          :disables="loading"
          @click="
            loader = 'loading';
            handleJoin();
          "
          >Join Game</v-btn
        >
      </v-col>
    </v-row>
    <v-row align="end" class="mt-10">
      <v-col align="center">
        Created with
        <v-btn icon @click.stop="handleEasterEgg()">
          <v-icon color="red">mdi-cards-heart</v-icon>
        </v-btn>
        in Scheinfeld
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IconSvg from "@/components/general/IconSvg";
import NameSvg from "@/components/general/NameSvg";

export default {
  name: "JoinViaInvite",
  title: "Join Game",
  components: {
    IconSvg,
    NameSvg,
  },
  props: {
    roomName: String,
  },
  data() {
    return {
      loader: null,
      loading: false,
      lobbyJoin: false,
      playerNameField: "",
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    handleJoin: function () {
      let payload = {
        playerName: this.playerNameField,
        roomName: this.roomName,
      };
      this.$socket.emit("joinGame", payload);
      this.$route.params.roomName = false;
    },
    goHome: function () {
      window.location.href = this.$gameUrl;
    },
    handleEasterEgg: function () {
      let notify = {
        type: "success",
        headline: "Warflash's Shlong",
        message: "8===========================D",
        duration: 5000,
      };
      this.$globalState.addNotification(notify);
    },

    //Text-Field rules
    isNotEmpty: function (input) {
      if (input == "") {
        return false;
      } else {
        return true;
      }
    },
    lengthGreater1: function (Input) {
      let invalidLengthMax = 1;
      try {
        if (invalidLengthMax < Input.length) {
          return true;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
    lengthSmaller21: function (Input) {
      let validLengthMax = 21;
      if (validLengthMax > Input.length) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
@keyframes color-rotate {
  from {
    filter: hue-rotate(0deg) saturate(200%) drop-shadow(16px 16px 10px black);
  }

  to {
    filter: hue-rotate(360deg) saturate(200%) drop-shadow(16px 16px 10px black);
  }
}

.logo:hover {
  animation: color-rotate 1s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
</style>