<template>
  <div>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <span class="primary--text text-md-h4 text-h5 font-weight-bold">
          Submit your question
        </span>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"> </v-col>
      <v-col cols="12" md="6" align="center">
        <v-form ref="form" v-model="validQuestion">
          <v-row>
            <v-col>
              <v-text-field
                label="Question"
                v-model="question"
                placeholder="Your awesome question"
                hint="Let's hope it's not too difficult :D"
                :rules="[isNotEmpty, multipleWords, isQuestion]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                placeholder="Awesome is not a type"
                label="Type"
                :items="typeItems"
                v-model="type"
                :rules="[isNotEmpty]"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                label="Genre"
                v-model="genre"
                placeholder="Throw your genre in"
                hint="Your fav genre ;)"
                :rules="[isNotEmpty]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Solution"
                v-model="solution"
                placeholder="2+2=4"
                hint="I guessed that value already :P"
                :rules="[isNotEmpty]"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                placeholder="inches ;)"
                label="Unit"
                :items="unitItems[type]"
                v-model="unit"
                :rules="[isNotEmpty]"
                no-data-text="Select a type first"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Evidence"
                v-model="evidence"
                height="80"
                placeholder="Provide evidence for your question"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <v-btn
          :disabled="!validQuestion"
          class="primary"
          @click.stop="handleSubmit()"
          >Submit my Question</v-btn
        >
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span class="secondary--text text-md-h5 text-h6 font-weight-bold"
          >How it works...</span
        >
        <div class="mt-3">
          Enter your question, type, genre, solution and unit.
          <br />You can also add evidence or a source to your question so that
          your question can be checked quicker. <br />After submitting your
          question our team will review your question and check for mistakes.
          <br />If your question fits into the game you may have to guess your
          own question soon in a match of GuessGame :D
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "submitQuestion",
  title: "Question-Submission",
  data() {
    return {
      validQuestion: false,
      question: "",
      genre: "",
      solution: "",
      unit: "",
      type: "",
      evidence: "",
      typeItems: [
        "length",
        "mass",
        "area",
        "volume",
        "temperature",
        "time",
        "speed",
        "digital",
        "default",
      ],
      unitItems: {
        length: ["mm", "cm", "m", "in", "ft-us", "ft", "mi"],
        mass: ["mcg", "mg", "g", "kg", "oz", "lb", "mt", "t"],
        area: ["mm2", "cm2", "m2", "ha", "km2", "in2", "ft2", "ac", "mi2"],
        volume: [
          "mm3",
          "cm3",
          "ml",
          "l",
          "kl",
          "m3",
          "km3",
          "tsp",
          "Tbs",
          "in3",
          "fl-oz",
          "cup",
          "pnt",
          "qt",
          "gal",
          "ft3",
          "yd3",
        ],
        temperature: ["C", "F", "K", "R"],
        time: ["ns", "mu", "ms", "s", "min", "h", "d", "week", "month", "year"],
        speed: ["m/s", "km/h", "m/h", "knot", "ft/s"],
        digital: ["b", "Kb", "Mb", "Gb", "Tb", "B", "KB", "MB", "GB", "TB"],
        default: ["No unit needed"],
      },
    };
  },
  methods: {
    isNotEmpty: function (input) {
      if (input == "") {
        return "This field must be filled!";
      } else {
        return true;
      }
    },
    isQuestion: function (input) {
      if (input.includes("?")) {
        return true;
      }
      return "This is not a question! '?' missing!";
    },
    multipleWords: function (input) {
      let wordcount = input.split(" ").length;
      if (wordcount > 1) {
        return true;
      }
      return "Mulitple words required";
    },
    handleSubmit: function () {
      let unitPayload = this.unit;
      if (this.type === "default") {
        unitPayload = "NoUnit";
      }
      let evidencePayload = "No Evidence submitted";
      if (this.evidence.length > 1) {
        evidencePayload = this.evidence;
      }
      let submission = {
        question: this.question,
        solution: this.solution,
        unit: unitPayload,
        type: this.type,
        genre: this.genre,
        evidence: evidencePayload,
      };
      axios
        .post(this.$restApiUrl + "/submit/question", JSON.stringify(submission))
        .then((response) => {
          let Notify = {
            duration: 4000,
            type: null,
            headline: "Question submission",
            message: "",
          };
          if (response.data.state) {
            Notify.type = "success";
            Notify.message = "Question successfully submitted!";
            this.$refs.form.reset();
          } else {
            Notify.type = "error";
            Notify.message = response.data.msg;
          }
          this.$globalState.addNotification(Notify);
        });
    },
  },
};
</script>

<style scoped>
</style>