import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import io from 'socket.io-client'
import tabTitle from './mixins/tabTitle'
import VueClipboard from 'vue-clipboard2'
import "./plugins/vuetify-money.js";

Vue.config.productionTip = false

//Cookies

Vue.use(require('vue-cookies'))
Vue.$cookies.config('604800s')

//Clipboard
Vue.use(VueClipboard)


//Mixins
Vue.mixin(tabTitle)

//Global vars

Vue.prototype.$connectionUrl = "https://guess-game-sockets.herokuapp.com/";
Vue.prototype.$dev = Vue.config.devtools;
Vue.prototype.$gameUrl = "https://playguess.games"

Vue.prototype.$restApiUrl = "https://api.playguess.games"
Vue.prototype.$authUrl = "https://discord.com/api/oauth2/authorize?client_id=823321054013423617&redirect_uri=https%3A%2F%2Fplayguess.games%2Fauth%2Fredirect&response_type=code&scope=identify"

if (Vue.prototype.$dev) {
  Vue.prototype.$authUrl = "https://discord.com/api/oauth2/authorize?client_id=822987379173883935&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fauth%2Fredirect&response_type=code&scope=identify"
  Vue.prototype.$connectionUrl = "http://127.0.0.1:3000"
  Vue.prototype.$gameUrl = "http://localhost:8080"
  Vue.prototype.$restApiUrl = "http://127.0.0.1:8787"
}

//Socket

Vue.prototype.$socket = io(Vue.prototype.$connectionUrl);

//Global states
//Reactive store

const store = Vue.observable({
  notifications: [],
  addNotification(notification) {
    store.notifications.push(notification);
  },
  getAndRemoveNotification() {
    return store.notifications.shift()
  },
  authedUser: {},
})

Vue.prototype.$globalState = store;

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')