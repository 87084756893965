<template>
  <div>
    <svg viewBox="0 0 60 40" :width="svgWidth" :height="svgHeight">
      <g>
        <path
          class="st0"
          d="M1.7,23.9c0-5.11,1.93-11.18,5.7-15c3.31-3.27,7.87-5.24,13.25-5.24c5.24,0,9.52,2.25,11.64,4.69l-6.07,6.85
		c-1.38-1.38-3.54-2.62-6.07-2.62c-2.25,0-4.05,0.83-5.29,2.07c-2.25,2.21-3.17,6.21-3.17,8.7c0,3.73,2.16,5.43,4.92,5.43
		c0.87,0,1.56-0.14,2.12-0.37l1.06-4.09H15.5l1.66-6.99h13.53l-4.23,17.02c-2.94,1.66-6.76,2.81-10.77,2.81
		C8.19,37.15,1.7,32.92,1.7,23.9z"
        />
      </g>
      <g>
        <path
          class="st1"
          d="M58.03,16.89c0.04,5.11-1.83,11.19-5.57,15.05c-3.28,3.29-7.82,5.31-13.2,5.36
		c-5.24,0.04-9.54-2.17-11.68-4.59l6.01-6.91c1.39,1.37,3.56,2.59,6.09,2.57c2.25-0.02,4.04-0.86,5.27-2.12
		c2.23-2.23,3.12-6.24,3.1-8.72c-0.03-3.73-2.21-5.41-4.97-5.39c-0.87,0.01-1.56,0.15-2.11,0.39l-1.02,4.1l4.28-0.04l-1.6,7.01
		l-13.53,0.12l4.08-17.06c2.93-1.68,6.74-2.86,10.74-2.9C51.43,3.69,57.96,7.87,58.03,16.89z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconSvg",
  props: {
    width: Number,
    height: Number,
  },
  computed: {
    svgWidth: function () {
      if (this.width && this.width > 0) {
        return this.width;
      }
      return "100%";
    },
    svgHeight: function () {
      if (this.height && this.height > 0) {
        return this.height;
      }
      return "100%";
    },
  },
};
</script>

<style type="text/css">
.st0 {
  fill: var(--v-primary-base);
}
.st1 {
  fill: var(--v-accentSecondary-base);
}
</style>

