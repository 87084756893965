<template>
  <div>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <span class="primary--text text-md-h4 text-h5 font-weight-bold">
          Report a Bug
        </span>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <v-form ref="form" v-model="validBug">
          <v-row>
            <v-col>
              <v-text-field
                label="Bug-Title"
                v-model="bugTitle"
                placeholder="What is this bug about?"
                hint="Choose something short but meaningful"
                :rules="[isNotEmpty, multipleWords]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                placeholder="Select a category"
                label="Category"
                :items="categoryItems"
                v-model="category"
                :rules="[isNotEmpty]"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                placeholder="Select a severity"
                label="Severity"
                :items="severityItems"
                v-model="severity"
                :rules="[isNotEmpty]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                counter
                placeholder="Add a meaningful description"
                label="Description"
                :rules="[isNotEmpty, multipleWords]"
                v-model="description"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <v-btn
          :disabled="!validBug"
          class="primary"
          @click.stop="handleSubmit()"
          >Report my Bug</v-btn
        >
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6">
        <span class="secondary--text text-md-h5 text-h6 font-weight-bold"
          >How it works...</span
        >
        <div class="mt-3">
          Enter your title, category, severity and description. After submitting
          your bug our team will review and check it.
          <br />Thank you for improving GuessGame!
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "submitBug",
  title: "Bug-Submission",
  data() {
    return {
      validBug: false,
      bugTitle: "",
      categoryItems: [
        "False data",
        "Displaying bug",
        "Missing functionalities",
        "Other",
      ],
      category: "",
      severityItems: ["Informational", "Low", "Medium", "High", "Critical"],
      severity: "",
      description: "",
    };
  },
  methods: {
    isNotEmpty: function (input) {
      if (input == "") {
        return "This field must be filled!";
      } else {
        return true;
      }
    },
    multipleWords: function (input) {
      let wordcount = input.split(" ").length;
      if (wordcount > 1) {
        return true;
      }
      return "Mulitple words required";
    },
    handleSubmit: function () {
      let submission = {
        title: this.bugTitle,
        category: this.category,
        severity: this.severity,
        description: this.description,
      };
      axios
        .post(this.$restApiUrl + "/submit/bug", JSON.stringify(submission))
        .then((response) => {
          let Notify = {
            duration: 4000,
            type: null,
            headline: "Bug submission",
            message: "",
          };
          if (response.data.state) {
            Notify.type = "success";
            Notify.message = "Bug successfully submitted!";
            this.$refs.form.reset();
          } else {
            Notify.type = "error";
            Notify.message = response.data.msg;
          }
          this.$globalState.addNotification(Notify);
        });
    },
  },
};
</script>

<style scoped>
</style>