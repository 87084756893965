<template>
  <div>
    <v-card width="250" color="accentSecondary" class="white--text">
      <v-row>
        <v-col align="center">
          <div align="center">
            <span class="headline">Lobby</span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="players.length > 0">
        <v-col class="mt-0 pt-0">
          <span class="subtitle-1">Players: {{ players.length }}</span>
        </v-col>
      </v-row>
      <div v-for="player in players" :key="player.id" align="center" class="">
        <PlayerCard
          class="mb-n2"
          :Name="player.name"
          :isPlayerList="true"
          :image="getProfileImg(player)"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import PlayerCard from "../Lobby/PlayerList/PlayerCard";

export default {
  name: "PlayerList",
  props: {
    players: Array,
  },
  components: {
    PlayerCard,
  },
  methods: {
    getProfileImg: function (player) {
      let img = "https://i.imgur.com/VVrWsei.png";
      if (Object.prototype.hasOwnProperty.call(player, "authUser")) {
        if (player.authUser.authorized) {
          img = player.authUser.image;
        }
      }
      return img;
    },
  },
};
</script>

<style scoped>
</style>