<template>
  <div>
    <v-card :color="cardColor" class="white--text" elevation="0">
      <v-row>
        <v-col align="center">
          {{ answer.playerName }}
        </v-col>
        <v-col align="center">
          {{
            new Intl.NumberFormat("de-DE", { style: "decimal" }).format(
              answer.answer
            )
          }}
          {{ displayUnit(answer) }}
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AnswersList",
  props: {
    answer: Object,
    position: Number,
    shortestDistance: Number,
    longestDistance: Number,
    totalAnswers: Number,
  },
  data() {
    return {
      calcToDisplay: {
        mm2: "mm²",
        cm2: "cm²",
        m2: "m²",
        km2: "km²",
        in2: "in²",
        ft2: "ft²",
        mi2: "mi²",
        mm3: "mm³",
        cm3: "cm³",
        m3: "m³",
        km3: "km³",
        in3: "in³",
        ft3: "ft³",
        C: "°C",
        F: "°F",
        "m/h": "mph",
      },
    };
  },
  computed: {
    cardColor: function () {
      let color = "accentSecondary";
      if (this.totalAnswers) {
        if (
          this.position === 0 ||
          this.shortestDistance === this.answer.distance
        ) {
          color = "primary";
        } else if (
          this.position === this.totalAnswers - 1 ||
          this.longestDistance === this.answer.distance
        ) {
          color = "error";
        }
      }
      return color;
    },
  },
  methods: {
    displayUnit: function (answer) {
      let unit = answer.unit;
      if (this.calcToDisplay[unit]) {
        return this.calcToDisplay[unit];
      }
      return unit;
    },
  },
};
</script>

<style scoped>
</style>