<template>
  <div>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <span class="primary--text text-md-h4 text-h5 font-weight-bold"
          >Join our Discord-Server!</span
        >
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <iframe
          :src="widgetUrl"
          width="350"
          height="350"
          allowtransparency="true"
          frameborder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        ></iframe>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="12" md="6" align="center">
        <span class="secondary--text text-md-h5 text-h6 font-weight-bold"
          >Official GuessGame Discord-Server</span
        >
        <div class="mt-3">
          Join our growing community of happy GuessGame-Player!
          <br />Meet new GuessGame-Player and enjoy playing or chat with our
          Devs :)
        </div>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "discord",
  title: "Discord",
  computed: {
    widgetUrl: function () {
      if (this.$vuetify.theme.dark === true) {
        return "https://discord.com/widget?id=804072914076827648&theme=dark";
      } else {
        return "https://discord.com/widget?id=804072914076827648&theme=light";
      }
    },
  },
};
</script>

<style scoped>
</style>