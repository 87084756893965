<template>
  <v-card dark :width="width">
    <v-card-title>
      <span class="headline">Game Information</span>
    </v-card-title>
    <v-card-text>
      <v-row class="hidden-sm-and-up">
        <v-form v-model="lobbyJoin">
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field
                autofocus
                style="width: 100px"
                label="Room-Name"
                v-model="gameIdField"
                :rules="[isNotEmpty, isLong5]"
                counter
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field
                label="Name"
                style="width: 100px"
                v-model="playerNameField"
                :rules="[isNotEmpty, lengthGreater1, lengthSmaller21]"
                @keyup.enter="
                  loader = 'loading';
                  handleJoin();
                "
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <v-btn
                :disabled="!lobbyJoin"
                class="pr-5 ma-4 mr-n1 white--text"
                color="primary"
                elevation="10"
                large
                :loading="loading"
                :disables="loading"
                @click="
                  loader = 'loading';
                  handleJoin();
                "
              >
                Submit & Join
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-form>
      </v-row>
      <v-row class="hidden-xs-only">
        <v-col>
          <v-form v-model="lobbyJoin">
            <v-row>
              <v-col>
                <v-text-field
                  autofocus
                  style="width: 100px"
                  label="Room-Name"
                  v-model="gameIdField"
                  :rules="[isNotEmpty, isLong5]"
                  counter
                ></v-text-field>
              </v-col>
              <v-col class="mr-n14">
                <v-text-field
                  label="Name"
                  style="width: 100px"
                  v-model="playerNameField"
                  :rules="[isNotEmpty, lengthGreater1, lengthSmaller21]"
                  @keyup.enter="
                    loader = 'loading';
                    handleJoin();
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col align="end">
          <v-btn
            :disabled="!lobbyJoin"
            class="pr-5 ma-4 mr-n1 white--text"
            color="primary"
            elevation="10"
            large
            :loading="loading"
            :disables="loading"
            @click="
              loader = 'loading';
              handleJoin();
            "
          >
            Submit & Join
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    width: Number,
  },
  data() {
    return {
      loader: null,
      loading: false,
      lobbyJoin: false,
      gameIdField: "",
      playerNameField: "",
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    handleJoin: function () {
      let payload = {
        playerName: this.playerNameField,
        roomName: this.gameIdField,
      };
      this.$socket.emit("joinGame", payload);
    },

    //Text-Field rules
    isNotEmpty: function (input) {
      if (input == "") {
        return false;
      } else {
        return true;
      }
    },
    isLong5: function (input) {
      if (input.length == 5) {
        return true;
      }
      return false;
    },
    lengthGreater1: function (Input) {
      let invalidLengthMax = 1;
      try {
        if (invalidLengthMax < Input.length) {
          return true;
        }
      } catch (err) {
        return false;
      }

      return false;
    },
    lengthSmaller21: function (Input) {
      let validLengthMax = 21;
      if (validLengthMax > Input.length) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
</style>