<template>
  <div>
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card v-if="player && !loading" color="accentSecondary" elevation="10">
      <v-row>
        <v-col>
          <v-badge
            overlap
            :content="place"
            bottom
            offset-x="45"
            :color="badgeColor"
          >
            <v-avatar size="70" style="border: 2px solid #41b883">
              <v-img v-if="player.avatar" :src="player.avatarUrl"></v-img>
              <v-img v-else src="https://i.imgur.com/VVrWsei.png"></v-img>
            </v-avatar>
          </v-badge>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="primary--text pb-0 text-truncate">
          {{ player.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <v-col v-bind="attrs" v-on="on" class="accentPrimary--text pt-0">
              {{ player.elo }}
            </v-col>
          </template>
          <span>Elo</span>
        </v-tooltip>
      </v-row>
      <v-divider></v-divider>
      <v-row class="d-flex justify-start align-center">
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <v-col v-bind="attrs" v-on="on" class="pr-0">
              {{ player.gamesPlayed }}
            </v-col>
          </template>
          <span>Played-Games</span>
        </v-tooltip>
        <v-divider vertical></v-divider>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <v-col v-bind="attrs" v-on="on">
              {{ player.totalPoints }}
            </v-col>
          </template>
          <span>Points</span>
        </v-tooltip>
        <v-divider vertical></v-divider>
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on, attrs }">
            <v-col v-bind="attrs" v-on="on" class="pl-0"
              >{{
                Math.round((player.winPercentage + Number.EPSILON) * 100)
              }}%</v-col
            >
          </template>
          <span>Win-Ratio</span>
        </v-tooltip>
      </v-row>
    </v-card>
    <v-card
      v-if="!player && !loading"
      color="accentSecondary"
      elevation="10"
      height="220"
      class="d-flex justify-start align-center"
    >
      <v-row class="px-4 primary--text">
        <v-col>
          <span>#{{ place }} is still available! Go get it!</span>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TopGuesser",
  props: {
    place: Number,
    player: Object,
    loading: Boolean,
  },
  computed: {
    badgeColor: function () {
      let color = "primary";

      switch (this.place) {
        case 1: {
          color = "#FFD700";
          break;
        }
        case 2: {
          color = "#AAA9AD";
          break;
        }
        case 3: {
          color = "#BF8970";
          break;
        }
        default: {
          color = "error";
          break;
        }
      }
      return color;
    },
  },
};
</script>