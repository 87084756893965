<template>
  <div>
    <v-row>
      <v-col class="pr-0">
        <v-icon class="mt-n1 mr-1" :color="actionMap[action].color">{{
          actionMap[action].icon
        }}</v-icon>
        <span :class="actionMap[action].color + '--text ml-1'"
          >{{ pointGain >= 1 ? "+" : " " }} {{ pointGain }}</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LbAction",
  props: {
    action: Number,
    pointGain: Number,
  },
  data() {
    return {
      actionMap: {
        "-1": {
          color: "red",
          icon: "mdi-arrow-down-thin-circle-outline",
        },
        0: {
          color: "blue",
          icon: "mdi-minus-circle-outline",
        },
        1: {
          color: "green",
          icon: "mdi-arrow-up-thin-circle-outline",
        },
      },
    };
  },
};
</script>