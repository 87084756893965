<template>
  <div>
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3" align="center">
        <PlayerList :players="players" />
      </v-col>
      <v-col cols="12" md="6" align="center">
        <ProgressBar
          :questionCount="questionCount"
          :currentQuestionNumber="currentRound"
        />
        <QuestionCard
          class="mb-5"
          :question="lastQuestion"
          :isHost="isHost"
          headline="Last Question"
          :gameState="gameState"
        />
        <AnswersList
          :answers="answers"
          :countDown="countDown"
          :question="lastQuestion"
          :gameState="gameState"
          :isHost="isHost"
        />
        <PlayerList class="my-5 hidden-md-and-up" :players="players" />
      </v-col>
      <v-col cols="3" align="center"> </v-col>
    </v-row>
  </div>
</template>

<script>
import PlayerList from "@/components/Lobby/PlayerList";
import QuestionCard from "@/components/Questions/QuestionCard";
import AnswersList from "@/components/Answers/AnswersList";
import ProgressBar from "@/components/general/ProgressBar";

export default {
  name: "Answers",
  title: "Answerphase",
  props: {
    answers: Array,
    players: Array,
    lastQuestion: Object,
    isHost: Boolean,
    gameState: Number,
    countDown: Number,
    currentRound: Number,
    questionCount: Number,
  },
  components: {
    PlayerList,
    QuestionCard,
    AnswersList,
    ProgressBar,
  },
};
</script>

<style scoped>
</style>