<template>
  <div class="home">
    <v-overlay :value="!validBrowser">
      <v-row justify="center">
        <v-col cols="12" md="6" >
          <v-card class="info">
            <v-row class="mb-n8">
              <v-col class="mt-4" align="center">
                <span
                  class="headline white--text font-weight-bold text-md-h5 text-h6"
                  >You are using Firefox!</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4">
                <p class="mt-5 px-10">
                  We currently only support the following Browsers completely:
                  <ul>
                    <li>Google Chrome</li>
                    <li>Apple Safari</li>
                    <li>Microsoft Edge</li>
                  </ul>
                  <br />
                  Please choose one of those Browsers for now to achieve the best gaming experience.
                  <br />We are working on that issue to support Mozilla Firefox some time soon!
                </p>
              </v-col>
            </v-row>
            <v-row class="pb-4" justify="center">
              <v-col class="pl-4" align="center">
                <v-btn class="secondary" @click="validBrowser = true"
                  >Got It!</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>
    <div>
      <div></div>
      <v-row>
        <v-col align="center">
          <IconSvg style="max-width: 450px" class="mb-0 logo"/>
          <NameSvg style="max-width: 450px" class="mb-0 logo"/>
        </v-col>
      </v-row>
      <div class="hidden-md-and-up">
        <v-row justify="center">
          <v-col align="center">
            <v-dialog :width="gameInformationWidth">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  elevation="20"
                  large
                  v-bind="attrs"
                  v-on="on"
                  class="white--text"
                >
                  Join Game
                </v-btn>
              </template>
              <GameInformation :width="gameInformationWidth" />
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn
              color="secondary"
              class="white--text"
              elevation="20"
              large
              :loading="loading"
              :disables="loading"
              @click="
                loader = 'loading';
                handleHost();
              "
            >
              Host Game
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-row class="hidden-sm-and-down" justify="center">
        <v-col cols="2" align="end">
          <v-dialog :width="gameInformationWidth">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                elevation="20"
                large
                v-bind="attrs"
                v-on="on"
                class="white--text"
              >
                Join Game
              </v-btn>
            </template>
            <GameInformation :width="gameInformationWidth" />
          </v-dialog>
        </v-col>
        <v-col cols="2" align="start">
          <v-btn
            color="secondary"
            class="white--text"
            elevation="20"
            large
            :loading="loading"
            :disables="loading"
            @click="
              loader = 'loading';
              handleHost();
            "
          >
            Host Game
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="end" class="mt-16">
        <v-col align="center">
          Created with
          <v-btn icon @click.stop="handleEasterEgg()">
            <v-icon color="red">mdi-cards-heart</v-icon>
          </v-btn>
          in Scheinfeld
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GameInformation from "@/components/Home/GameInformation";
import IconSvg from "@/components/general/IconSvg";
import NameSvg from "@/components/general/NameSvg";

export default {
  name: "Home",
  title: "Home",
  components: {
    GameInformation,
    IconSvg,
    NameSvg,
  },
  data() {
    return {
      loader: null,
      loading: false,
      gameInformationWidth: 500,
      validBrowser: false,
    };
  },
  mounted() {
    this.getBrowser();
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    handleHost: function () {
      this.$socket.emit("hostOverview");
    },
    handleEasterEgg: function () {
      let notify = {
        type: "success",
        headline: "Warflash's Shlong",
        message: "8===========================D",
        duration: 5000,
      };
      this.$globalState.addNotification(notify);
    },
    getBrowser: function () {
      let agent = navigator.userAgent;
      if (
        agent.includes("Safari") ||
        agent.includes("Chrome") ||
        agent.includes("Edg")
      ) {
        this.validBrowser = true;
      } else {
        this.validBrowser = false;
      }
    },
  },
};
</script>

<style scoped>
@keyframes color-rotate {
  from {
    filter: hue-rotate(0deg) saturate(200%) drop-shadow(16px 16px 10px black);
  }

  to {
    filter: hue-rotate(360deg) saturate(200%) drop-shadow(16px 16px 10px black);
  }
}

.logo:hover {
  animation: color-rotate 1s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
</style>
