<template>
  <div>
    <v-system-bar
      fixed
      class="pa-0"
      dark
      color="accentPrimary"
      :height="systemBarHeight"
      :elevation="20"
    >
      <v-row>
        <v-col class="py-0 d-flex justify-center">
          <div @click="disconnectPlayer()">
            <NameSvg
              :height="systemBarHeight"
              style="max-width: 200px; cursor: pointer"
              class="ma-0 pa-0 pt-2"
            />
          </div>
        </v-col>
      </v-row>
    </v-system-bar>
  </div>
</template>

<script>
import NameSvg from "@/components/general/NameSvg";

export default {
  name: "SystemBar",
  components: {
    NameSvg,
  },
  data() {
    return {
      userInfo: false,
      systemBarHeight: 50,
      drawer: false,
      loading: true,
      playerCurrentPoints: "",
      elements: [
        {
          title: "Play a Game",
          link: "/",
          icon: "mdi-nintendo-game-boy",
          group: false,
        },
        {
          title: "Documentation",
          link: "/documentation",
          icon: "mdi-book-open-blank-variant",
          group: true,
          items: [
            {
              title: "What is GuessGame?",
              link: "/documentation/#WhatIsGuessGame",
              icon: "mdi-information",
              subGroup: false,
            },
            {
              title: "How to Play",
              link: "/documentation/#howtoplay",
              icon: "mdi-clipboard-text-play",
              subGroup: true,
              subItems: [
                {
                  title: "Rules",
                  link: "/documentation/#Rules",
                  icon: "mdi-format-list-bulleted-square",
                },
                {
                  title: "Host a Game",
                  link: "/documentation/#HostGame",
                  icon: "mdi-plus-box",
                },
                {
                  title: "Join a Game",
                  link: "/documentation/#JoinGame",
                  icon: "mdi-location-enter",
                },
              ],
            },
            {
              title: "Login via Discord",
              link: "/documentation/#LoginViaDiscord",
              icon: "mdi-discord",
              subGroup: true,
              subItems: [
                {
                  title: "Advantages",
                  link: "/documentation/#Advantages",
                  icon: "mdi-tag-plus",
                },
                {
                  title: "How to Login",
                  link: "/documentation/#HowToLogin",
                  icon: "mdi-login-variant",
                },
              ],
            },
          ],
        },
        {
          title: "Improve the Game",
          link: "",
          icon: "mdi-pencil",
          group: true,
          items: [
            {
              title: "Submit your Question",
              link: "/submit/Question",
              icon: "mdi-chat-question",
              subGroup: false,
            },
            {
              title: "Report a Bug",
              link: "/submit/Bug",
              icon: "mdi-shield-bug",
              subGroup: false,
            },
            {
              title: "Submit Feedback",
              link: "/submit/Feedback",
              icon: "mdi-comment-quote",
              subGroup: false,
            },
          ],
        },
        {
          title: "Leaderboard",
          link: "/leaderboard",
          icon: "mdi-format-list-numbered",
        },
        {
          title: "Discord-Server",
          link: "/discord",
          icon: "mdi-discord",
        },
        // { title: "Tip the Creators :)", link: "/donate", icon: "mdi-cash" },
      ],
      accountOptions: [
        {
          title: "Login via Discord",
          link: "/auth/redirect",
          icon: "mdi-discord",
        },
      ],
    };
  },
  computed: {
    userAuthed: function() {
      if (
        Object.prototype.hasOwnProperty.call(this.$globalState.authedUser, "id")
      ) {
        return true;
      }
      return false;
    },
    userImage: function() {
      return this.$globalState.authedUser.profileImg;
    },
  },
  mounted() {
    this.getPlayerCurrentPoints();
  },
  updated() {
    this.getPlayerCurrentPoints();
  },
  methods: {
    getPlayerCurrentPoints: async function() {
      let url =
        this.$restApiUrl +
        "/data/player/" +
        this.$cookies.get("authUserId") +
        "/0/0";
      try {
        let response = await fetch(url, {
          method: "GET",
        });
        let dataResponse = await response.json();
        this.playerCurrentPoints = dataResponse.playerStats.current.totalPoints;
      } catch {
        this.playerCurrentPoints = "TBD";
      }
    },
    handleProfile: function() {
      if (this.playerCurrentPoints == "TBD") {
        let Notify = {
          duration: 5000,
          type: "info",
          headline: "No Profile available",
          message:
            "You need to play at least one game before we can create a profile for you!",
        };
        this.$globalState.addNotification(Notify);
      } else {
        this.$router.push("/profile");
        let Notify = {
          duration: 4000,
          type: "info",
          headline: "Profile - BETA",
          message:
            "This Feature is still in BETA-State! Please report misbehavior via the Bug-Form!",
        };
        this.$globalState.addNotification(Notify);
      }
    },
    handleLogout: function() {
      this.$cookies.remove("authToken");
      this.$cookies.remove("authUserId");
      this.$globalState.authedUser = {};
      let Notify = {
        duration: 4000,
        type: "success",
        headline: "Successfully logged out!",
        message: "",
      };
      this.$globalState.addNotification(Notify);
    },
    disconnectPlayer: function() {
      window.location.href = this.$gameUrl;
    },
    handleQuestionSubmit: function() {
      let notify = {
        type: "info",
        headline: "Coming Soon",
        message: "This function is still worked on :)",
        duration: 5000,
      };
      this.$globalState.addNotification(notify);
    },
    handleTip: function() {
      let notify = {
        type: "info",
        headline: "Coming Soon",
        message: "This function is still worked on :)",
        duration: 5000,
      };
      this.$globalState.addNotification(notify);
    },
    handleDiscordAuth: function() {
      window.location = this.$authUrl;
    },
  },
};
</script>

<style scoped></style>
