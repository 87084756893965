<template>
  <div>
    <svg viewBox="0 0 200 60" :width="svgWidth" :height="svgHeight">
      <g>
        <path
          class="st0"
          d="M2.75,29.4c0-4.11,1.55-8.99,4.59-12.06C10,14.71,13.67,13.12,18,13.12c4.22,0,7.66,1.81,9.36,3.77l-4.88,5.51
		c-1.11-1.11-2.85-2.11-4.88-2.11c-1.81,0-3.26,0.67-4.25,1.67c-1.81,1.78-2.55,5-2.55,6.99c0,3,1.74,4.37,3.96,4.37
		c0.7,0,1.26-0.11,1.7-0.3l0.85-3.29h-3.44l1.33-5.62h10.88l-3.4,13.69c-2.37,1.33-5.44,2.26-8.66,2.26
		C7.97,40.05,2.75,36.65,2.75,29.4z"
        />
        <path
          class="st0"
          d="M25.84,34.73c0-0.92,0.11-1.85,0.41-3l2.96-12.4h7.77l-2.66,11.03c-0.11,0.56-0.19,1.04-0.19,1.41
		c0,0.85,0.44,1.41,1.41,1.41c1.26,0,2-0.89,2.41-2.55l2.74-11.29h7.73l-4.88,20.2h-7.77l0.59-2.41c-1.22,1.52-3.15,2.89-5.51,2.89
		C27.58,40.02,25.84,37.91,25.84,34.73z"
        />
        <path
          class="st0"
          d="M54.44,31.62c0.07,1.67,1.11,2.52,2.85,2.52c1.26,0,2.63-0.44,4.07-1.41l2.85,4.66
		c-2,1.59-4.66,2.63-7.84,2.63c-5.7,0-9.07-3.37-9.07-8.66c0-3.29,1.26-6.96,3.74-9.44c1.92-1.92,4.44-3.07,7.55-3.07
		c5.74,0,7.96,3.81,7.96,8.18c0,1.52-0.3,3.18-0.7,4.59H54.44z M54.96,27.81h4.59c0.15-0.55,0.19-0.96,0.19-1.33
		c0-1.07-0.63-1.92-1.7-1.92c-0.59,0-1.22,0.26-1.78,0.81C55.7,25.92,55.26,26.81,54.96,27.81z"
        />
        <path
          class="st0"
          d="M65.02,36.83l3.33-5c1.67,1.44,4.22,2.66,6.03,2.66c0.78,0,1.11-0.33,1.11-0.74c0-0.63-0.56-0.89-2.55-1.85
		c-2.89-1.44-4.74-2.96-4.74-5.81c0-4.03,3.11-7.25,8.55-7.25c3.29,0,6.22,0.96,8.4,2.63l-3.33,5c-1.33-1.04-3.52-2.11-5.18-2.11
		c-0.78,0-1.18,0.26-1.18,0.7c0,0.52,0.41,0.7,2.44,1.67c2.78,1.33,4.88,2.81,4.88,5.99c0,3.52-2.44,7.29-8.4,7.29
		C70.68,40.02,67.43,38.8,65.02,36.83z"
        />
        <path
          class="st0"
          d="M82.41,36.83l3.33-5c1.67,1.44,4.22,2.66,6.03,2.66c0.78,0,1.11-0.33,1.11-0.74c0-0.63-0.55-0.89-2.55-1.85
		c-2.89-1.44-4.74-2.96-4.74-5.81c0-4.03,3.11-7.25,8.55-7.25c3.29,0,6.22,0.96,8.4,2.63l-3.33,5c-1.33-1.04-3.51-2.11-5.18-2.11
		c-0.78,0-1.18,0.26-1.18,0.7c0,0.52,0.41,0.7,2.44,1.67c2.78,1.33,4.88,2.81,4.88,5.99c0,3.52-2.44,7.29-8.4,7.29
		C88.07,40.02,84.82,38.8,82.41,36.83z"
        />
        <path
          class="st1"
          d="M102.24,29.4c0-4.11,1.55-8.99,4.59-12.06c2.67-2.63,6.33-4.22,10.66-4.22c4.22,0,7.66,1.81,9.36,3.77
		l-4.88,5.51c-1.11-1.11-2.85-2.11-4.88-2.11c-1.81,0-3.26,0.67-4.25,1.67c-1.81,1.78-2.55,5-2.55,6.99c0,3,1.74,4.37,3.96,4.37
		c0.7,0,1.26-0.11,1.7-0.3l0.85-3.29h-3.44l1.33-5.62h10.88l-3.4,13.69c-2.37,1.33-5.44,2.26-8.66,2.26
		C107.46,40.05,102.24,36.65,102.24,29.4z"
        />
        <path
          class="st1"
          d="M124.92,34.87c0-2.04,0.7-4.18,2.04-5.51c1.29-1.33,3.37-2.07,5.96-2.07c1.48,0,2.78,0.26,4.11,0.74l0.07-0.26
		c0.07-0.3,0.15-0.7,0.15-1c0-1.22-0.93-1.92-2.78-1.92c-1.63,0-3.77,0.44-4.88,0.89l-0.89-5.7c2.26-0.67,4.88-1.04,7.59-1.04
		c5.81,0,8.77,2.29,8.77,6.33c0,0.85-0.11,1.81-0.33,2.78l-2.78,11.43h-7.73l0.44-1.81c-1.55,1.37-2.89,2.26-5,2.26
		C126.85,39.98,124.92,38.09,124.92,34.87z M136.02,32.69l0.33-1.52c-0.44-0.15-1.07-0.26-1.59-0.26c-0.74,0-1.26,0.22-1.7,0.67
		c-0.55,0.55-0.85,1.41-0.85,2.22c0,1,0.37,1.59,1.29,1.59C134.62,35.39,135.65,34.32,136.02,32.69z"
        />
        <path
          class="st1"
          d="M148.79,19.33h7.73l-0.56,2.33c1.22-1.48,3.07-2.81,5.48-2.81c2.52,0,3.92,1.33,4.51,3.11
		c1.74-1.92,3.92-3.11,6.51-3.11c3.48,0,5.18,2.11,5.18,5.22c0,0.96-0.18,1.92-0.41,2.96l-3,12.51h-7.73l2.7-11.1
		c0.11-0.44,0.19-0.96,0.19-1.33c0-0.85-0.41-1.41-1.37-1.41c-1.18,0-1.96,0.85-2.37,2.55l-2.74,11.29h-7.73l2.7-11.1
		c0.11-0.44,0.18-0.96,0.18-1.33c0-0.85-0.41-1.41-1.37-1.41c-1.18,0-1.96,0.85-2.37,2.55l-2.74,11.29h-7.73L148.79,19.33z"
        />
        <path
          class="st1"
          d="M185.05,31.62c0.07,1.67,1.11,2.52,2.85,2.52c1.26,0,2.63-0.44,4.07-1.41l2.85,4.66
		c-2,1.59-4.66,2.63-7.84,2.63c-5.7,0-9.07-3.37-9.07-8.66c0-3.29,1.26-6.96,3.74-9.44c1.92-1.92,4.44-3.07,7.55-3.07
		c5.74,0,7.96,3.81,7.96,8.18c0,1.52-0.3,3.18-0.7,4.59H185.05z M185.57,27.81h4.59c0.15-0.55,0.18-0.96,0.18-1.33
		c0-1.07-0.63-1.92-1.7-1.92c-0.59,0-1.22,0.26-1.78,0.81C186.31,25.92,185.86,26.81,185.57,27.81z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "NameSvg",
  props: {
    width: Number,
    height: Number,
  },
  computed: {
    svgWidth: function () {
      if (this.width && this.width > 0) {
        return this.width;
      }
      return "100%";
    },
    svgHeight: function () {
      if (this.height && this.height > 0) {
        return this.height;
      }
      return "100%";
    },
  },
};
</script>

<style type="text/css">
.st0 {
  fill: var(--v-primary-base);
}
.st1 {
  fill: var(--v-accentSecondary-base);
}
</style>