<template>
  <v-app>
    <SystemBar />
    <v-container class="mt-16" :fluid="$vuetify.breakpoint.width <= 1920">
      <div>
        <v-row>
          <v-col align="center">
            <IconSvg style="max-width: 350px" class="mb-0 logo" />
            <NameSvg style="max-width: 350px" class="mb-0 logo" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="hidden-sm-and-down"></v-col>
          <v-col class="d-flex justify-center text-h5 font-weight-bold">
            <span>Thanks for playing with us!</span>
          </v-col>
          <v-col cols="4" class="hidden-sm-and-down"></v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="hidden-sm-and-down"></v-col>
          <v-col>
            We have sad news. From August the 26th 2022 on GuessGame will be
            closed. It was a blast developing for you and most definetley
            playing with you all. Unfortunatley though the server costs were
            greater than the use of GuessGame. We thank all that have been
            playing with us and helped improving the game. <br />
            <br />
            Goodbye! <br />
            <br />
            <span>The Devs </span>
            <v-btn icon @click.stop="handleEasterEgg()">
              <v-icon color="red">mdi-cards-heart</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" class="hidden-sm-and-down"></v-col>
        </v-row>
      </div>
      <Notification />
    </v-container>
  </v-app>
</template>

<script>
import Notification from "./components/general/Notification.vue";
import SystemBar from "./components/general/SystemBar";
import IconSvg from "./components/general/IconSvg.vue";
import NameSvg from "./components/general/NameSvg.vue";

export default {
  name: "App",
  components: {
    SystemBar,
    Notification,
    IconSvg,
    NameSvg,
  },
  methods: {
    handleEasterEgg: function() {
      let notify = {
        type: "success",
        headline: "Warflash's Shlong",
        message: "8===========================D",
        duration: 5000,
      };
      this.$globalState.addNotification(notify);
    },
  },
};
</script>

<style scoped></style>
