<template>
  <div>
    <v-card
      color="accentSecondary"
      class="white--text"
      height="50"
      elevation="0"
    >
      <v-row>
        <v-col class="pl-0" align="center" :class="textCard">
          {{ player.playerName }}
        </v-col>
        <v-col class="px-0" align="center">
          {{ player.points }}
        </v-col>
        <v-col class="pr-0" v-if="gameState !== 4" align="center">
          <LbAction
            class="mt-n2"
            :action="player.lbAction"
            :pointGain="player.pointGain"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import LbAction from "./LbAction";
export default {
  name: "LbCard",
  props: {
    player: Object,
    clientId: String,
    gameState: Number,
  },
  components: {
    LbAction,
  },
  computed: {
    textCard: function () {
      if (this.clientId === this.player.playerId) {
        return "text-decoration-underline font-weight-bold";
      }
      return "";
    },
  },
};
</script>

<style scoped>
</style>