<template>
  <div>
    <v-progress-linear
      class="mb-4"
      rounded
      :value="progress"
      color="primary"
      buffer-value="100"
      :indeterminate="progressLoading"
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  data() {
    return {
      progress: 0,
      progressLoading: true,
    };
  },
  props: {
    questionCount: Number,
    currentQuestionNumber: Number,
  },
  mounted() {
    this.calcProgress();
  },
  methods: {
    calcProgress: function () {
      if (this.questionCount != 0 && this.questionCount) {
        this.progress =
          (1 / this.questionCount) * this.currentQuestionNumber * 100;
        this.progressLoading = false;
      }
    },
  },
};
</script>