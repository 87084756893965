<template>
  <div>
    <v-row>
      <v-col>
        <span class="text-md-h4 text-sm-h5 text-h6 font-weight-bold"
          >Creating your game...</span
        >
      </v-col>
      <v-col align="end">
        <v-btn v-if="this.$dev" @click="logSelected" class="mr-5"
          >Log Selected Array</v-btn
        >
        <v-btn
          color="primary"
          class="white--text"
          @click="
            loader = 'loadingHost';
            handleCreate();
          "
          :disabled="!validGame"
          :loading="loadingHost"
          :disables="loadingHost"
          light
          >Finished! Host my Game!</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card width="550" height="60">
          <v-row justify="center">
            <v-col align="center" class="mx-3">
              <v-slider
                v-model="timeSlider"
                min="10"
                max="300"
                thumb-label
                color="primary"
                label="Time Per Question"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ value }}s
                </template>
              </v-slider>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          multi-sort
          v-model="selected"
          :single-select="false"
          :headers="headers"
          :items="questions"
          item-key="questionId"
          show-select
          class="elevation-1 mt-5"
          :items-per-page="questions.length"
          :footer-props="{
            showCurrentPage: true,
          }"
        >
          <template v-slot:top>
            <!-- mobile -->
            <v-card class="hidden-md-and-up" elevation="0">
              <v-row>
                <v-col>
                  <v-card-title class="font-weight-bold">
                    Select the questions you want to add to your game. At least
                    2!
                  </v-card-title>
                </v-col>
              </v-row>
              <v-row class="px-8">
                <v-col align="center">
                  <v-slider
                    v-model="randomQuestionsCount"
                    min="2"
                    :max="questions.length"
                    thumb-label
                    color="primary"
                    label="Question Count"
                  >
                  </v-slider>
                  <v-btn @click="randomSelect()"
                    >Select Random Questions and Start</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="px-8">
                <v-col>
                  <span class="pl-4">
                    Selected: {{ selected.length }}/{{ questions.length }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- desktop -->
            <v-card class="hidden-sm-and-down" elevation="0">
              <v-row>
                <v-col>
                  <v-card-title class="font-weight-bold">
                    Select the questions you want to add to your game. At least
                    2!
                  </v-card-title>
                </v-col>
                <v-col cols="3" align="center">
                  <v-slider
                    v-model="randomQuestionsCount"
                    min="2"
                    :max="questions.length"
                    thumb-label
                    color="primary"
                    label="Question Count"
                  >
                  </v-slider>
                  <v-btn @click="randomSelect()"
                    >Select Random Questions and Start</v-btn
                  >
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="pl-4">
                    Selected: {{ selected.length }}/{{ questions.length }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Host",
  title: "Host Game",
  props: {
    questions: Array,
  },
  data() {
    return {
      randomQuestionsCount: 2,
      socket: {},
      minQuestionsToStart: 2,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "Question",
          align: "start",
          sortable: false,
          value: "question",
        },
        // {
        //   text: "Solution",
        //   sortable: false,
        //   value: "solution",
        // },
        // {
        //   text: "Unit",
        //   sortable: false,
        //   value: "unit",
        // },
        {
          text: "Type",
          sortable: true,
          value: "type",
        },
        {
          text: "Genre",
          sortable: true,
          value: "genre",
        },
      ],
      curPlayerId: "",
      loader: null,
      loadingHost: false,
      timeSlider: 200,
    };
  },
  computed: {
    validGame: function () {
      if (this.selected.length >= this.minQuestionsToStart) {
        return true;
      }
      return false;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  methods: {
    logSelected: function () {
      console.log(JSON.stringify(this.selected));
    },
    handleCreate: function () {
      let payload = {
        name: "Host",
        questionIds: this.selected.map((q) => q.questionId),
        timePerQuestion: this.timeSlider,
      };
      this.$socket.emit("newGame", payload);
    },
    randomSelect: function () {
      this.selected = [];
      let randomNumbers = [];
      for (let i = 0; i < this.randomQuestionsCount; i++) {
        let randomNumberCount = randomNumbers.length;
        do {
          let randomItem = Math.floor(Math.random() * this.questions.length);
          if (!randomNumbers.includes(randomItem)) {
            randomNumbers.push(randomItem);
            this.selected.push(this.questions[randomItem]);
          }
        } while (randomNumberCount === randomNumbers.length);
      }
    },
  },
};
</script>

<style scoped>
</style>