<template>
  <div>
    <v-card color="accentSecondary" class="white--text">
      <v-row>
        <v-col align="center">
          <span class="headline font-weight-bold text-md-h5 text-h6"
            >Players Guesses</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Timer :countDown="countDown" />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="font-weight-bold">Player</v-col>
        <v-col align="center" class="font-weight-bold">Answer</v-col>
      </v-row>
      <v-row>
        <v-col v-if="isHost && gameState === 2">
          <div v-for="answer in answers" :key="answer.playerId">
            <AnswerCard :answer="answer" />
          </div>
        </v-col>
        <v-col v-else>
          <div v-for="(answer, index) in answerArray" :key="answer.playerId">
            <AnswerCard
              :answer="answer"
              :position="index"
              :shortestDistance="answerArray[0].distance"
              :totalAnswers="answerArray.length"
              :longestDistance="answerArray[answerArray.length - 1].distance"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import AnswerCard from "./AnswersList/AnswersCard";
import Timer from "../Answers/Timer";
export default {
  name: "AnswersList",
  props: {
    answers: Array,
    countDown: Number,
    question: Object,
    gameState: Number,
    isHost: Boolean,
  },
  data() {
    return {
      answerArray: [],
    };
  },
  components: {
    AnswerCard,
    Timer,
  },
  methods: {
    sortAnswers: function (array, solution) {
      const sorted = [];
      for (let i = 0; i < array.length; i++) {
        sorted[i] = {
          playerId: array[i].playerId,
          playerName: array[i].playerName,
          answer: array[i].answer,
          unit: array[i].unit,
          distance: Math.abs(solution - array[i].normalized),
        };
      }
      sorted.sort(function (a, b) {
        return a.distance - b.distance;
      });
      return sorted;
    },
  },
  mounted() {
    if (this.gameState === 3) {
      this.answerArray = this.sortAnswers(
        this.answers,
        this.question.normalized
      );
    }
  },
};
</script>

<style scoped>
</style>