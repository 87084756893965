<template>
  <div>
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3" align="center">
        <div v-if="!loading">
          <v-card color="primary" class="accentSecondary--text" max-width="320">
            <v-row>
              <v-col
                class="
                  font-weight-bold
                  text-lg-h5 text-md-h5 text-sm-h6 text-h6
                "
              >
                Leaderboard Sorting
              </v-col>
            </v-row>
            <v-row>
              <v-col> Sorting Property </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <v-select
                  style="max-width: 170px"
                  :items="sortingPropertyItems"
                  v-model="sortingProperty"
                  color="accentSecondary"
                  solo
                  background-color="accentSecondary"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0"> Amount Leaderboard Entities </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  style="max-width: 90px"
                  :items="amountEntitiesItems"
                  v-model="amountEntities"
                  color="accentSecondary"
                  solo
                  background-color="accentSecondary"
                  v-on:change="getLeaderboard"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div v-else>
          <v-skeleton-loader
            style="max-width: 320px"
            type="card"
          ></v-skeleton-loader>
        </div>
      </v-col>
      <v-col cols="12" md="6" align="center">
        <div v-if="!loading" class="pb-8">
          <v-card class="hidden-sm-and-up" color="primary">
            <v-row>
              <v-col>
                <span
                  class="
                    px-4
                    accentSecondary--text
                    font-weight-bold
                    text-lg-h3 text-md-h4 text-sm-h5 text-h5
                  "
                  >The GuessGame Alltime-Leaderboard</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span
                  class="
                    px-4
                    accentSecondary--text
                    text-lg-h4 text-md-h5 text-sm-h6 text-h6
                  "
                  >Top 3 Guesser</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <TopGuesser
                  style="max-width: 320px"
                  class="px-4"
                  :place="1"
                  :player="leaderboardEntities[0]"
                  :loading="loading"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <TopGuesser
                  style="max-width: 320px"
                  class="px-4"
                  :place="2"
                  :player="leaderboardEntities[1]"
                  :loading="loading"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <TopGuesser
                  style="max-width: 320px"
                  class="px-4"
                  :place="3"
                  :player="leaderboardEntities[2]"
                  :loading="loading"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-card class="hidden-xs-only" color="primary">
            <v-row>
              <v-col>
                <span
                  class="
                    px-4
                    accentSecondary--text
                    font-weight-bold
                    text-lg-h3 text-md-h4 text-sm-h5 text-h5
                  "
                  >The GuessGame Alltime-Leaderboard</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span
                  class="
                    px-4
                    accentSecondary--text
                    text-lg-h4 text-md-h5 text-sm-h6 text-h6
                  "
                  >Top 3 Guesser</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <TopGuesser
                  style="max-width: 220px"
                  class="px-4 mt-8"
                  :place="2"
                  :player="leaderboardEntities[1]"
                  :loading="loading"
                />
              </v-col>
              <v-col>
                <TopGuesser
                  style="max-width: 220px"
                  class="px-4"
                  :place="1"
                  :player="leaderboardEntities[0]"
                  :loading="loading"
                />
              </v-col>
              <v-col>
                <TopGuesser
                  style="max-width: 220px"
                  class="px-4 pb-4 mt-16"
                  :place="3"
                  :player="leaderboardEntities[2]"
                  :loading="loading"
                />
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div v-if="loading">
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </div>
        <div v-if="!loading" class="pb-8 hidden-sm-and-up">
          <v-card color="primary" class="accentSecondary--text">
            <v-row>
              <v-col
                class="
                  pb-0
                  font-weight-bold
                  text-lg-h5 text-md-h5 text-sm-h6 text-h6
                "
              >
                Leaderboard Sorting
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0"> Sorting Property </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <v-select
                  style="max-width: 170px"
                  :items="sortingPropertyItems"
                  v-model="sortingProperty"
                  color="accentSecondary"
                  solo
                  background-color="accentSecondary"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0"> Amount Leaderboard Entities </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <v-select
                  style="max-width: 90px"
                  :items="amountEntitiesItems"
                  v-model="amountEntities"
                  color="accentSecondary"
                  solo
                  background-color="accentSecondary"
                  v-on:change="getLeaderboard"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div v-if="!loading" class="pb-8 hidden-md-and-up hidden-xs-only">
          <v-card color="primary" class="accentSecondary--text">
            <v-row>
              <v-col
                class="
                  pb-0
                  font-weight-bold
                  text-lg-h5 text-md-h5 text-sm-h6 text-h6
                "
              >
                Leaderboard Sorting
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-3" align="end"> Sorting Property </v-col>
              <v-col class="pb-0" align="start">
                <v-select
                  style="max-width: 170px"
                  :items="sortingPropertyItems"
                  v-model="sortingProperty"
                  color="accentSecondary"
                  solo
                  background-color="accentSecondary"
                ></v-select>
              </v-col>
              <v-col class="py-0" align="end">
                Amount Leaderboard Entities
              </v-col>
              <v-col class="pb-0" align="start">
                <v-select
                  style="max-width: 90px"
                  :items="amountEntitiesItems"
                  v-model="amountEntities"
                  color="accentSecondary"
                  solo
                  background-color="accentSecondary"
                  v-on:change="getLeaderboard"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div v-else>
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </div>
        <div v-if="!loading">
          <v-card class="hidden-sm-and-up" color="primary">
            <v-row class="accentSecondary--text font-weight-bold">
              <v-col>Pos. | Avatar</v-col>
              <v-col>Name | Elo</v-col>
              <v-col>Points | Games</v-col>
              <v-col>Wins | Win-Ratio</v-col>
            </v-row>
            <v-row
              v-for="player in remainingLeaderboard"
              :key="player.totalPoints"
              class="
                pt-0
                accentSecondary--text
                d-flex
                justify-start
                align-center
              "
            >
              <v-col class="pt-0">
                <v-row>
                  <v-col class="pt-0"> #{{ player.pos }} </v-col>
                  <v-col class="pt-0 text-truncate">
                    {{ player.name }}
                  </v-col>
                  <v-col class="pt-0">
                    {{ player.gamesPlayed }}
                  </v-col>
                  <v-col class="pt-0">
                    {{ player.gamesWon }}
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col class="pt-0">
                    <v-avatar size="35" style="border: 2px solid #35495e">
                      <v-img
                        v-if="player.avatar"
                        :src="player.avatarUrl"
                      ></v-img>
                      <v-img
                        v-else
                        src="https://i.imgur.com/VVrWsei.png"
                      ></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col class="pt-0">
                    {{ player.elo }}
                  </v-col>
                  <v-col class="pt-0">
                    {{ player.totalPoints }}
                  </v-col>
                  <v-col class="pt-0">
                    {{
                      Math.round((player.winPercentage + Number.EPSILON) * 100)
                    }}%
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="hidden-xs-only" color="primary">
            <v-row
              class="
                accentSecondary--text
                font-weight-bold
                d-flex
                justify-start
                align-center
              "
            >
              <v-col>Pos.</v-col>
              <v-col> Avatar </v-col>
              <v-col> Name </v-col>
              <v-col> Elo </v-col>
              <v-col> Points </v-col>
              <v-col> Games </v-col>
              <v-col> Wins </v-col>
              <v-col> Win-Ratio </v-col>
            </v-row>
            <v-row
              v-for="player in remainingLeaderboard"
              :key="player.totalPoints"
              class="accentSecondary--text d-flex justify-start align-center"
            >
              <v-col> #{{ player.pos }}</v-col>
              <v-col>
                <v-avatar style="border: 3px solid #35495e">
                  <v-img v-if="player.avatar" :src="player.avatarUrl"></v-img>
                  <v-img v-else src="https://i.imgur.com/VVrWsei.png"></v-img>
                </v-avatar>
              </v-col>
              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="text-truncate" v-bind="attrs" v-on="on">
                    {{ player.name }}
                  </v-col>
                </template>
                <span>{{ player.name }}</span>
              </v-tooltip>
              <v-col>
                {{ player.elo }}
              </v-col>
              <v-col>
                {{ player.totalPoints }}
              </v-col>
              <v-col>
                {{ player.gamesPlayed }}
              </v-col>
              <v-col>
                {{ player.gamesWon }}
              </v-col>
              <v-col>
                {{ Math.round((player.winPercentage + Number.EPSILON) * 100) }}%
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
      <v-col class="hidden-sm-and-down" cols="3" align="center"></v-col>
    </v-row>
  </div>
</template>

<script>
import TopGuesser from "@/components/alltimeLeaderboard/TopGuesser.vue";

export default {
  name: "Alltime-Leaderboard",
  title: "Alltime-Leaderboard",
  data() {
    return {
      leaderboardData: {},
      loading: true,
      sortingPropertyItems: ["Points", "Played Games", "Win-Ratio", "Elo"],
      amountEntitiesItems: ["5", "10", "25", "50", "100", "All"],
      sortingProperty: "Points",
      amountEntities: "10",
      sortingPropertyMap: {
        Points: "totalPoints",
        "Played Games": "gamesPlayed",
        "Win-Ratio": "winPercentage",
        Elo: "elo",
      },
    };
  },
  components: {
    TopGuesser,
  },
  mounted() {
    this.getLeaderboard();
  },
  computed: {
    leaderboardEntities: function () {
      let leaderboard = this.leaderboardData.leaderboard.players;
      let lbe = [];
      for (let i = 0; i < leaderboard.length; i++) {
        let entity = leaderboard[i];
        entity.pos = i + 1;
        lbe.push(entity);
      }
      lbe.sort(
        (a, b) =>
          b[this.sortingPropertyMap[this.sortingProperty]] -
          a[this.sortingPropertyMap[this.sortingProperty]]
      );

      return lbe;
    },
    remainingLeaderboard: function () {
      let leaderboard = this.leaderboardEntities;
      let remain = [];
      if (leaderboard.length > 3) {
        for (let i = 3; i < leaderboard.length; i++) {
          let entity = leaderboard[i];
          entity.pos = i + 1;
          remain.push(entity);
        }
      }
      return remain;
    },
  },
  methods: {
    getLeaderboard: async function () {
      let leaderboardUrl =
        this.$restApiUrl + "/data/leaderboard/" + this.amountEntities;
      try {
        let response = await fetch(leaderboardUrl, {
          method: "GET",
        });

        let dataResponse = await response.json();
        if (dataResponse.state) {
          this.leaderboardData = dataResponse;
          this.loading = false;
        } else {
          let Notify = {
            duration: 4000,
            type: "error",
            headline: "Alltime-Leaderboard",
            message: dataResponse.msg + " Be the first one and play now! :)",
          };
          this.$globalState.addNotification(Notify);
        }
      } catch {
        let Notify = {
          duration: 4000,
          type: "error",
          headline: "Alltime-Leaderboard",
          message: "Unable to load leaderboard! Please try again later...",
        };
        this.$globalState.addNotification(Notify);
      }
    },
  },
};
</script>

<style scoped>
</style>