<template>
  <div>
    <!-- Current Player Info -->
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3" align="center"></v-col>
      <v-col v-if="loading" cols="12" md="6" align="center">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
      <v-col v-else cols="12" md="6" align="center">
        <v-card color="primary">
          <v-row>
            <v-col>
              <v-avatar size="110" style="border: 4px solid #35495e">
                <v-img :src="user.profileImg"></v-img>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                class="accentSecondary--text font-weight-bold text-lg-h3 text-md-h4 text-sm-h5 text-h5"
                >{{ user.name }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" align="center">
              <span
                class="accentSecondary--text text-lg-h5 text-md-h6 text-sm-subtitle-1 text-subtitle-1"
                >Points
              </span>
            </v-col>
            <v-col class="pb-0" align="center">
              <span
                class="accentSecondary--text text-lg-h5 text-md-h6 text-sm-subtitle-1 text-subtitle-1"
                >Played Games</span
              >
            </v-col>
            <v-col class="pb-0" align="center">
              <span
                class="accentSecondary--text text-lg-h5 text-md-h6 text-sm-subtitle-1 text-subtitle-1"
                >Games Won</span
              >
            </v-col>
            <v-col class="pb-0" align="center">
              <span
                class="accentSecondary--text text-lg-h5 text-md-h6 text-sm-subtitle-1 text-subtitle-1"
                >Win-Ratio</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" align="center">
              <span
                class="accentSecondary--text text-lg-h5 text-md-h6 text-sm-subtitle-1 text-subtitle-1"
                >{{ playerStats.current.totalPoints }}
              </span>
            </v-col>
            <v-col class="pt-0" align="center">
              <span
                class="accentSecondary--text text-lg-h5 text-md-h6 text-sm-subtitle-1 text-subtitle-1"
                >{{ playerStats.current.gamesPlayed }}</span
              >
            </v-col>
            <v-col class="pt-0" align="center">
              <span
                class="accentSecondary--text text-lg-h5 text-md-h6 text-sm-subtitle-1 text-subtitle-1"
                >{{ playerStats.current.gamesWon }}</span
              >
            </v-col>
            <v-col class="pt-0" align="center">
              <span
                class="accentSecondary--text text-lg-h5 text-md-h6 text-sm-subtitle-1 text-subtitle-1"
                >{{
                  Math.round(
                    (playerStats.current.winPercentage + Number.EPSILON) * 100
                  )
                }}
                %</span
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="hidden-sm-and-down" cols="3" align="center"></v-col>
    </v-row>
    <!-- Time-Line -->
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3" align="center"> </v-col>
      <v-col v-if="loading" cols="12" md="6" align="center">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
      <v-col v-else cols="12" md="6" align="center">
        <v-card color="primary">
          <v-row>
            <v-col align="center">
              <span
                class="accentSecondary--text font-weight-bold text-lg-h4 text-md-h5 text-sm-h6 text-h6"
                >Timeline</span
              >
            </v-col>
          </v-row>
          <div v-if="showTimeLine">
            <div v-if="playerStats.timeLine.length > 1">
              <!-- Mobile Start -->
              <v-row v-if="$vuetify.breakpoint.width < 600">
                <v-col class="py- 0 accentSecondary--text">
                  Range Timeline-Entities
                </v-col>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.width < 600">
                <v-col class="py- 0 accentSecondary--text">
                  <v-range-slider
                    class="px-8"
                    v-model="amountChartEntities"
                    color="accentSecondary"
                    min="0"
                    :max="playerStats.timeLine.length"
                    thumb-label
                  ></v-range-slider>
                </v-col>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.width < 600">
                <v-col class="py- 0 accentSecondary--text">
                  Timeline Property
                </v-col>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.width < 600">
                <v-col class="py- 0 accentSecondary--text">
                  <v-select
                    style="max-width: 250px"
                    class="px-8 mt-n3"
                    :items="timeLineItems"
                    v-model="chartInput"
                    color="accentSecondary"
                    solo
                    background-color="accentSecondary"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.width < 600">
                <v-col class="py- 0 accentSecondary--text"
                  >Amount Timeline-Entities</v-col
                >
              </v-row>
              <v-row v-if="$vuetify.breakpoint.width < 600">
                <v-col class="py- 0 accentSecondary--text">
                  <v-select
                    class="px-8 mt-n3"
                    style="width: 140px"
                    :items="timeLineAmountItems"
                    v-model="amountTimeLineEntities"
                    color="accentSecondary"
                    solo
                    background-color="accentSecondary"
                    v-on:change="getPlayerStats"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- Mobile End -->
              <!-- Desktop Start -->
              <v-row v-if="$vuetify.breakpoint.width > 600">
                <v-col class="accentSecondary--text">
                  Range Timeline-Entities
                </v-col>
                <v-col class="accentSecondary--text"> Timeline Property </v-col>
                <v-col class="accentSecondary--text">
                  Amount Timeline-Entities</v-col
                >
              </v-row>
              <v-row v-if="$vuetify.breakpoint.width > 600">
                <v-col>
                  <v-range-slider
                    class="px-8"
                    v-model="amountChartEntities"
                    color="accentSecondary"
                    min="0"
                    :max="playerStats.timeLine.length"
                    thumb-label
                  ></v-range-slider>
                </v-col>
                <v-col>
                  <v-select
                    class="px-8 mt-n3"
                    :items="timeLineItems"
                    v-model="chartInput"
                    color="accentSecondary"
                    solo
                    background-color="accentSecondary"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    class="px-8 mt-n3"
                    style="width: 140px"
                    :items="timeLineAmountItems"
                    v-model="amountTimeLineEntities"
                    color="accentSecondary"
                    solo
                    background-color="accentSecondary"
                    v-on:change="getPlayerStats"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- Desktop End -->
              <v-row>
                <v-col>
                  <v-sparkline
                    :value="chartValues"
                    padding="14"
                    stroke-linecap="round"
                    smooth
                    height="100"
                    color="accentSecondary"
                    auto-draw
                    type="trend"
                    line-width="2"
                    show-labels
                  >
                  </v-sparkline>
                </v-col>
              </v-row>
            </div>
            <v-row v-else>
              <v-col>
                <span class="accentSecondary--text"
                  >Play at least one more game to see your progress here!</span
                >
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <v-btn
                icon
                color="accentSecondary"
                @click="showTimeLine = !showTimeLine"
              >
                <v-icon v-if="!showTimeLine">mdi-arrow-down-drop-circle</v-icon>
                <v-icon v-else>mdi-arrow-up-drop-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="hidden-sm-and-down" cols="3" align="center"> </v-col>
    </v-row>
    <!-- Game History -->
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3" align="center"> </v-col>
      <v-col v-if="loading" cols="12" md="6" align="center">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
      <v-col v-else cols="12" md="6" align="center">
        <v-card color="primary">
          <v-row>
            <v-col align="center">
              <span
                class="accentSecondary--text font-weight-bold text-lg-h4 text-md-h5 text-sm-h6 text-h6"
                >Game History</span
              >
            </v-col>
          </v-row>
          <div v-if="showGameHistory">
            <v-row>
              <v-col class="accentSecondary--text pr-0 mr-n2" align="end"
                >Amount Games</v-col
              >
              <v-col class="pl-0 ml-n2 pb-0" align="start">
                <v-select
                  class="px-8 mt-n3"
                  style="width: 140px"
                  :items="gamesAmountItems"
                  v-model="amountGameHistoyEntities"
                  color="accentSecondary"
                  solo
                  background-color="accentSecondary"
                  v-on:change="getPlayerStats"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="$vuetify.breakpoint.width < 600"
              class="hidden-md-and-up px-4"
            >
              <v-col class="accentSecondary--text pt-0">
                Turn your Device to view more Stats of each game
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="px-3 accentSecondary--text font-weight-bold"
                v-on:click="changeSorting('Date')"
                style="cursor: pointer"
                >Date
                <v-icon
                  v-if="gameHistorySortProperty == 'Date' && sortDescending"
                  >mdi-chevron-down</v-icon
                >
                <v-icon
                  v-if="gameHistorySortProperty == 'Date' && !sortDescending"
                  >mdi-chevron-up</v-icon
                >
              </v-col>
              <v-col
                class="px-3 accentSecondary--text font-weight-bold"
                v-on:click="changeSorting('Duration')"
                style="cursor: pointer"
                >Duration
                <v-icon
                  v-if="gameHistorySortProperty == 'Duration' && sortDescending"
                  >mdi-chevron-down</v-icon
                >
                <v-icon
                  v-if="
                    gameHistorySortProperty == 'Duration' && !sortDescending
                  "
                  >mdi-chevron-up</v-icon
                >
              </v-col>
              <v-col
                class="px-3 accentSecondary--text font-weight-bold"
                v-on:click="changeSorting('Placement')"
                style="cursor: pointer"
                >Placement
                <v-icon
                  v-if="
                    gameHistorySortProperty == 'Placement' && sortDescending
                  "
                  >mdi-chevron-down</v-icon
                >
                <v-icon
                  v-if="
                    gameHistorySortProperty == 'Placement' && !sortDescending
                  "
                  >mdi-chevron-up</v-icon
                >
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.width > 600"
                class="px-3 accentSecondary--text font-weight-bold"
                v-on:click="changeSorting('Player-Count')"
                style="cursor: pointer"
                >Player-Count
                <v-icon
                  v-if="
                    gameHistorySortProperty == 'Player-Count' && sortDescending
                  "
                  >mdi-chevron-down</v-icon
                >
                <v-icon
                  v-if="
                    gameHistorySortProperty == 'Player-Count' && !sortDescending
                  "
                  >mdi-chevron-up</v-icon
                >
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.width > 600"
                class="px-3 accentSecondary--text font-weight-bold"
                v-on:click="changeSorting('Question-Count')"
                style="cursor: pointer"
                >Question-Count
                <v-icon
                  v-if="
                    gameHistorySortProperty == 'Question-Count' &&
                    sortDescending
                  "
                  >mdi-chevron-down</v-icon
                >
                <v-icon
                  v-if="
                    gameHistorySortProperty == 'Question-Count' &&
                    !sortDescending
                  "
                  >mdi-chevron-up</v-icon
                >
              </v-col>
            </v-row>
            <v-row v-for="game in gameHistoryEntites" :key="game.gameId">
              <v-col class="px-3 accentSecondary--text">{{
                buildDateString(game.gameDate)
              }}</v-col>
              <v-col class="px-3 accentSecondary--text">{{
                millisToMinutesAndSeconds(game.gameDuration)
              }}</v-col>
              <v-col class="px-3 accentSecondary--text">{{
                game.placement
              }}</v-col>
              <v-col
                v-if="$vuetify.breakpoint.width > 600"
                class="px-3 accentSecondary--text"
                >{{ game.playerCount }}</v-col
              >
              <v-col
                v-if="$vuetify.breakpoint.width > 600"
                class="px-3 accentSecondary--text"
                >{{ game.questionCount }}</v-col
              >
            </v-row>
          </div>
          <v-row>
            <v-col>
              <v-btn
                icon
                color="accentSecondary"
                @click="showGameHistory = !showGameHistory"
              >
                <v-icon v-if="!showGameHistory"
                  >mdi-arrow-down-drop-circle</v-icon
                >
                <v-icon v-else>mdi-arrow-up-drop-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="hidden-sm-and-down" cols="3" align="center"> </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "profile",
  title: "Profile",
  data() {
    return {
      loading: true,
      playerStats: {},
      chartInput: "Points",
      amountTimeLineEntities: "5",
      amountGameHistoyEntities: "5",
      amountChartEntities: [0, 3],
      timeLineItems: ["Points", "Elo", "Wins", "Played Games"],
      selectMap: {
        Points: "totalPoints",
        Elo: "elo",
        Wins: "gamesWon",
        "Played Games": "gamesPlayed",
      },
      gamesAmountItems: ["5", "10", "25", "50", "All"],
      timeLineAmountItems: ["5", "10", "25", "50", "All"],
      gameHistorySortProperty: "Date",
      sortMap: {
        Date: "gameDate",
        Duration: "gameDuration",
        Placement: "placement",
        "Player-Count": "playerCount",
        "Question-Count": "questionCount",
      },
      sortDescending: true,
      showTimeLine: false,
      showGameHistory: false,
    };
  },
  computed: {
    user: function () {
      return this.$globalState.authedUser;
    },
    chartValues: function () {
      let timeLine = this.playerStats.timeLine;
      let chartItems = [];
      let amountItems = this.amountChartEntities;

      if (this.chartEntities > timeLine.length) {
        amountItems = timeLine.length;
      }
      for (let i = amountItems[0]; i < amountItems[1]; i++) {
        chartItems.push(timeLine[i][this.selectMap[this.chartInput]]);
      }
      return chartItems;
    },
    gameHistoryEntites: function () {
      let gameHistoryArray = this.playerStats.gameHistory;
      if (this.sortDescending) {
        gameHistoryArray.sort(
          (a, b) =>
            b[this.sortMap[this.gameHistorySortProperty]] -
            a[this.sortMap[this.gameHistorySortProperty]]
        );
      } else {
        gameHistoryArray.sort(
          (a, b) =>
            a[this.sortMap[this.gameHistorySortProperty]] -
            b[this.sortMap[this.gameHistorySortProperty]]
        );
      }

      return gameHistoryArray;
    },
  },
  created() {
    if (!this.$cookies.get("authToken")) {
      this.$router.push("/");
      let Notify = {
        duration: 4000,
        type: "error",
        headline: "Login required",
        message: "You need to be logged in to view this page!",
      };
      this.$globalState.addNotification(Notify);
    } else {
      this.getPlayerStats();
    }
  },
  updated() {
    if (!this.$cookies.get("authToken")) {
      this.$router.push("/");
      let Notify = {
        duration: 4000,
        type: "error",
        headline: "Login required",
        message: "You need to be logged in to view this page!",
      };
      this.$globalState.addNotification(Notify);
    }
  },
  methods: {
    changeSorting: function (sortProperty) {
      this.gameHistorySortProperty = sortProperty;
      this.sortDescending = !this.sortDescending;
    },
    millisToMinutesAndSeconds: function (millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    },
    buildDateString: function (epoch) {
      let date = new Date(epoch);
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let dateString =
        date.getUTCDate() + "." + month + "." + date.getFullYear();
      return dateString;
    },
    getPlayerStats: async function () {
      if (this.$cookies.get("authUserId")) {
        let playerStatsUrl =
          this.$restApiUrl +
          "/data/player/" +
          this.$cookies.get("authUserId") +
          "/" +
          this.amountTimeLineEntities +
          "/" +
          this.amountGameHistoyEntities;

        let response = "";
        try {
          response = await fetch(playerStatsUrl, {
            method: "GET",
          });
        } catch {
          let Notify = {
            duration: 4000,
            type: "error",
            headline: "Failed loading!",
            message:
              "Unable to load PlayerStats at the moment. Please try again later...",
          };
          this.$globalState.addNotification(Notify);
        }

        let dataResponse = await response.json();

        if (dataResponse.state) {
          this.playerStats = dataResponse.playerStats;
          this.loading = false;
          let endChart = dataResponse.playerStats.timeLine.length;
          this.amountChartEntities = [0, endChart];
        } else {
          let Notify = {
            duration: 4000,
            type: "error",
            headline: "Failed loading!",
            message:
              "Unable to load PlayerStats at the moment. Please try again later...",
          };
          this.$globalState.addNotification(Notify);
        }
      }
    },
  },
};
</script>

<style scoped>
</style>